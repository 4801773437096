/* eslint-disable */
import React, { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import toast from 'react-hot-toast';

import SignatureCanvas from 'react-signature-canvas';

import { Button, Text } from '@eltoro-ui/components';
import { Loading } from 'Components/Loading';
import SubscriptionCard from 'Components/SubscriptionPlanChoose/components/SubscriptionCard';
import SignatureModal from 'Components/SubscriptionPlanChoose/components/SignatureModal';

import Maskgroup from 'assets/Images/Maskgroup.svg';
import cross from 'assets/Images/cross.png';

import { uploadTos } from 'Requests/Request_Methods/authUserMethods';
import { createSignedTOS } from 'Utils/helpers';

import { setUser } from 'Redux/actions';

import type { TCommonSubscription, TRootState } from 'types';

import './SubscriptionPlanChoose.scss';

type SubscriptionPlanChooseProps = {
  loading: boolean;
  onContinue: () => void;
  subscriptions: TCommonSubscription[];
  selectedSubscription: TCommonSubscription | null;
  setSelectedSubscription: (subscription: TCommonSubscription) => void;
};

const SubscriptionPlanChoose = ({
  loading,
  subscriptions,
  onContinue,
  selectedSubscription,
  setSelectedSubscription,
}: SubscriptionPlanChooseProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userData } = useSelector((state: TRootState) => state.userReducer);

  const [signLoading, setSignLoading] = useState<boolean>(false);
  const [signIsEmpty, setSignIsEmpty] = useState<boolean>(true);
  const signatureCanvasRef = useRef<SignatureCanvas>(null);

  const [signModalOpen, setSignModalOpen] = useState<boolean>(false);

  const onClose = () => setSignModalOpen(false);

  const onContinueWithoutSign = () => setSignModalOpen(true);

  const closedSection = () => {
    window.localStorage.clear();
    history.go(0);
  };

  const onSelectSubscription = (subscription: TCommonSubscription) => () => {
    setSelectedSubscription(subscription);
  };

  const onConfirmSign = async () => {
    try {
      setSignLoading(true);
      const signatureURL = signatureCanvasRef.current?.getTrimmedCanvas().toDataURL('image/png');
      const { file } = await createSignedTOS(signatureURL as string, '/terms-of-use.pdf', userData);

      const formData = new FormData();
      formData.set('tos_file', file);

      await uploadTos(formData);
      dispatch(setUser({ ...userData, has_signature: true }));
      onContinue();
    } catch (e) {
      if ('detail' in e) toast.error(e.detail);
      else toast.error('Something went wrong!');
    } finally {
      setSignLoading(false);
    }
  };

  const continueFunction = useMemo(() => {
    if (userData?.has_signature) return onContinue;
    return onContinueWithoutSign;
  }, [userData, signIsEmpty]);

  return (
    <div className="ChooseSubscription" style={{ position: 'relative', overflow: 'hidden' }}>
      <img src={cross} onClick={closedSection} alt="cross" className="ChooseSubscription__cross" />
      <div className="ChooseSubscription__description">
        <div className="ChooseSubscription__header">
          <Text on="white" UNSAFE_className="heading">
            Choose Your Plan For Beta
          </Text>
          <Text on="grey-500" UNSAFE_className="sub_heading">
            As a member of our beta cohort, you will receive a free subscription trial for the
            duration of the beta period, ending December 9, 2024.
            <br /> We recommended getting the most out of your experience and selecting 'Premium'!
          </Text>
        </div>
      </div>
      <div className="ChooseSubscription__inputs">
        <div className="ChooseSubscription__inputs-form">
          {loading && <Loading />}
          {subscriptions.map((subscription, index) => (
            <SubscriptionCard
              key={`${subscription.id}${index}`}
              subscription={subscription}
              selectedSubscriptionId={selectedSubscription?.id}
              onSelectSubscription={onSelectSubscription}
            />
          ))}
        </div>
        <Button
          kind="primary"
          size="l"
          UNSAFE_className="subscription-form-submit"
          onClick={continueFunction}
          disabled={!selectedSubscription}
        >
          Continue
        </Button>
      </div>
      <img
        style={{
          position: 'absolute',
          left: '-56px',
          width: '35%',
          bottom: '-82px',
        }}
        src={Maskgroup}
        alt="beewo-logo-back"
      />
      {signModalOpen && (
        <SignatureModal
          signEmpty={[signIsEmpty, setSignIsEmpty]}
          signatureCanvasRef={signatureCanvasRef}
          signLoading={signLoading}
          onConfirmSign={onConfirmSign}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default SubscriptionPlanChoose;

import React, { ReactNode, CSSProperties } from 'react';
import './HeaderItem.scss';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

interface HeaderItemCommonPropsType {
  children?: ReactNode;
  icon?: JSX.Element;
  UNSAFE_CLASSNAME?: string;
  UNSAFE_STYLE?: CSSProperties;
  UNSAFE_CLASSNAME_NAVLINK_ITEM?: string;
  iconClassname?: string;
  childrenClassName?: string;
  disabled?: boolean;
  hasRecommendations?: boolean;
}

interface HeaderItemWithLinkPropsType {
  to: string | null;
  replace?: boolean;
  onClick?: any;
}

interface HeaderItemWithOnClickPropsType {
  to?: undefined;
  replace?: boolean;
  onClick: () => void;
}

type HeaderItemPropsType = HeaderItemCommonPropsType &
  (HeaderItemWithLinkPropsType | HeaderItemWithOnClickPropsType);

export const HeaderItem = ({
  to,
  onClick,
  children,
  icon,
  UNSAFE_CLASSNAME,
  UNSAFE_CLASSNAME_NAVLINK_ITEM,
  UNSAFE_STYLE,
  iconClassname,
  childrenClassName,
  disabled,
  replace = false,
  hasRecommendations = false,
}: HeaderItemPropsType) => {
  const Location = useLocation();
  if (to) {
    return (
      <NavLink
        className={classNames('HeaderItem', UNSAFE_CLASSNAME_NAVLINK_ITEM, disabled && 'disabled')}
        to={to}
        onClick={onClick}
        replace={replace}
      >
        {!!icon && (
          <span
            className={`${children ? 'HeaderItem__icon' : ''} ${iconClassname}`}
            style={UNSAFE_STYLE}
          >
            {hasRecommendations && <span className="recommendation_indicator">&#x2022;</span>}
            {icon}
          </span>
        )}
        <span
          className={`${childrenClassName} ${
            Location.pathname.includes(to) && 'HeaderItem--is-active'
          }`}
        >
          {children}
        </span>
      </NavLink>
    );
  }
  return (
    <button
      className={classNames('HeaderItem', UNSAFE_CLASSNAME, disabled && 'disabled')}
      style={UNSAFE_STYLE}
      type="button"
      // onClick={onClick ? onClick : undefined}
      onClick={onClick}
    >
      {!!icon && (
        <span
          className={`${children ? 'HeaderItem__icon' : ''} ${iconClassname}`}
          // className={`${children ? 'HeaderItem__icon 2' : ''}`}
        >
          {icon}
        </span>
      )}
      {children}
    </button>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import Tippy from '@tippyjs/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

import { Button } from '@eltoro-ui/components';
import { Loading } from 'Components';
import { ListingLabel } from 'Pages/ProspectActivity/components';

import CustomIcons from 'assets/icons';

import { setIsAudienceSaved } from 'Redux/actions';
import AllListingsModal from 'Pages/ProspectActivity/components/ListingsList/components/AllListingsModal';

import type { LensSidebarHeaderProps } from 'Pages/ProspectActivity/lenses/components';

import type { APIFeatureType, TRootState } from 'types';

import './ListingsList.scss';

export interface ListingsListProps
  extends Pick<LensSidebarHeaderProps, 'toggle' | 'isToggle' | 'toggleText' | 'isToggleOpen'> {
  listings: APIFeatureType[];
  selectedListings: APIFeatureType[];
  loading: boolean;
  onSelectedListingsChange: (newSelectedListings: APIFeatureType[]) => void;
  type?: string;
  isToggle?: any;
}

export const ListingsList = ({
  listings,
  selectedListings,
  loading,
  onSelectedListingsChange,
  toggle,
  toggleText = 'Show also inactive (sold) listings',
  isToggleOpen,
  isToggle,
  type,
}: ListingsListProps) => {
  const dispatch = useDispatch();

  const isAudienceSaved = useSelector((state: TRootState) => state.isSavedAudienceReducer);
  const { refreshList } = useSelector((state: TRootState) => state.RefreshListingReducer);

  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [viewAllListings, setViewAllListings] = useState<boolean>(false);

  const handleConfirm =
    (inactiveListing: boolean, modalSelectedListings: APIFeatureType[]) => () => {
      isToggle(inactiveListing);
      onSelectedListingsChange(modalSelectedListings);
      setViewAllListings(false);
    };

  useEffect(() => {
    if (selectedListings.length !== listings.length) setAllChecked(false);
  }, [selectedListings, listings, viewAllListings]);

  const handleListingLabelOnClick = (listing: APIFeatureType) => {
    const matchingListing = selectedListings.find(l => l.listingid === listing.listingid);
    if (matchingListing)
      onSelectedListingsChange(selectedListings.filter(l => l.listingid !== listing.listingid));
    else onSelectedListingsChange([...selectedListings, listing]);
  };

  useEffect(() => {
    if (isAudienceSaved.isAudienceSaved) {
      onSelectedListingsChange([]);
      setAllChecked(false);
      dispatch(setIsAudienceSaved(false));
    }
  }, [isAudienceSaved]);

  const toggleViewAllListings = () => setViewAllListings(view => !view);

  if (loading) return <Loading />;

  if (!refreshList.length && type === 'Virtual Just Listed/Just Sold')
    return (
      <div className="ListingsList">
        <div className="ListingsList__empty">
          <CustomIcons name="empty_listing" fontSize={64} />
          <div className="ListingsList__empty__content">
            <span className="title">You do not have any listings</span>
            <span className="description">
              Please make sure you provide the correct MLS registered name and ZIP Code.
            </span>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className={classNames(
        'ListingsList',
        type === 'Virtual Just Listed/Just Sold' ? 'virtual-just-listed-just-sold' : 'type'
      )}
    >
      <div
        className={classNames(
          'ListingsList__listings ListingsList__listingScroll',
          type === 'Virtual Just Listed/Just Sold' && 'VirtualJustListedJustSold__listings_content'
        )}
      >
        <div className="ListingsList__header">
          <div className="ListingsList__count">
            <span className="count">Your Listings ({listings?.length})</span>
            <Tippy
              className="audience-pending-tooltip listing-tooltip"
              placement="top-start"
              hideOnClick={false}
              popperOptions={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-20, 10],
                    },
                  },
                ],
              }}
              content={
                <span>
                  The outcomes are sourced from the past 90 days <br /> and within a 100-mile radius
                  of your main zip code.
                </span>
              }
            >
              <span>
                <FontAwesomeIcon icon={faInfoCircle} color="#FFAB03" style={{ fontSize: 20 }} />
              </span>
            </Tippy>
          </div>
          {!!selectedListings.length && (
            <span className="selected_listings_count">{selectedListings.length} Selected</span>
          )}
        </div>
        <div className="VirtualJustListedJustSold__listings">
          {!listings.length && (
            <span className="empty-filtered-status">
              You do not have any listings with the applied filters.
            </span>
          )}
          {listings.slice(0, 7).map(listing => (
            <ListingLabel
              key={listing.listingid}
              isSelected={
                !!selectedListings.find(selectedL => selectedL.listingid === listing.listingid)
              }
              listing={listing}
              onClick={handleListingLabelOnClick}
            />
          ))}
          {listings.length > 3 && (
            <Button
              kind="text"
              UNSAFE_className="view_all_listings"
              onClick={toggleViewAllListings}
            >
              View all listings
            </Button>
          )}
        </div>
      </div>
      {viewAllListings && (
        <AllListingsModal
          listings={refreshList}
          setAllChecked={setAllChecked}
          selectedListings={selectedListings}
          allChecked={allChecked}
          toggleViewAllListings={toggleViewAllListings}
          handleConfirm={handleConfirm}
          isToggleOpen={isToggleOpen}
          toggleText={toggleText}
          isToggle={isToggle}
          toggle={toggle}
        />
      )}
    </div>
  );
};

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import { useLocations } from 'Hooks';
import { Button, MaxHeightContainer, Spacer } from '@eltoro-ui/components';
import { Map, MapPopup, MapSearch, MapToolTip, mapLocationResolver } from 'Components';
import {
  AudienceResultsCard,
  LocationLabels,
  MapMarker,
  SaveAudienceWizard,
} from 'Pages/ProspectActivity/components';
import {
  LensSidebar,
  LensSidebarFooter,
  LensSidebarHeader,
} from 'Pages/ProspectActivity/lenses/components';

import { setSidebarLens } from 'Redux/actions';
import { getDigitalProspects, getPlaceGeoJSON } from 'Requests/Request_Methods/prospectMethods';
import { getMapListings } from 'Requests';
import { getDecryptedData, getIconBuzzColor } from 'Helpers';

import { compactNumberConverter } from 'Utils/helpers';

import type { APIFeatureType, TLocation, TDigitalProspectsRes } from 'types';

import './DigitalFarming.scss';

export const DigitalFarming = () => {
  const { locations, addLocation, hasLocations, clearLocation } = useLocations();
  const [polygonLocation, setPolygonLocation] = useState<TLocation | null>(null);
  const [features, setFeatures] = useState<APIFeatureType[]>([]);
  const dispatch = useDispatch();

  const showProspectsModal = () => {
    return locations.length >= 1 && isClicked.isLoaded;
  };
  const [residentialStatus, setResidentialStatus] = useState<TDigitalProspectsRes | null>(null);
  const [dataObj, setdataObj] = useState<any>();
  const [loading, setLoading] = useState(false);

  const resetStates = () => {
    setIsClicked({ isCancel: false, isLoaded: false });
    clearLocation();
    setResidentialStatus(null);
    setPolygonLocation(null);
  };

  const totalProspects = residentialStatus?.Unlisted.count ?? 0;

  const searchProspect = useCallback((polygon: TLocation) => {
    setLoading(true);
    getDigitalProspects({
      bounding_box: polygon.bounding_box,
    })
      .then(async res => {
        if (res?.data) {
          const data = await getDecryptedData(res.data.response);
          console.log(data, 'data');
          setResidentialStatus(data);
          setIsClicked({ ...isClicked, isLoaded: true });
          setdataObj({
            bounding_box: polygon.bounding_box,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
        if (!polygon) dispatch(setSidebarLens(true));
      });
  }, []);

  useEffect(() => {
    if (hasLocations) {
      getMapListings(locations.map(location => mapLocationResolver(location))).then(res => {
        if (res.data) {
          setFeatures(res.data.listings);
        }
      });
    } else {
      setFeatures([]);
    }
  }, [hasLocations, locations]);

  const [isClicked, setIsClicked] = useState({
    isCancel: false,
    isLoaded: false,
  });

  const getPolygon = async (): Promise<TLocation | null> => {
    try {
      setLoading(true);
      if (polygonLocation) setPolygonLocation(null);
      const data = await getPlaceGeoJSON(
        locations[0].originalFeature.properties.id,
        locations[0].originalFeature.properties.layer
      );
      if (data.length) {
        setPolygonLocation(data[0]);
        return data[0];
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

    return null;
  };

  const onFindSellers = async () => {
    const polygon = await getPolygon();
    if (polygon) await searchProspect(polygon);
  };

  return (
    <div className="DigitalFarming">
      {residentialStatus && (
        <div className="DigitalFarming_residential_status_container">
          <span className="DigitalFarming_residential_status_container_title">
            Residential Status
          </span>
          <div className="DigitalFarming_residential_status_container_status_content">
            {Object.entries(residentialStatus)
              .sort()
              .map(([label, { count, percentage }]) => (
                <div
                  key={label}
                  className="DigitalFarming_residential_status_container_status_item"
                >
                  <div className="DigitalFarming_residential_status_container_status_item_title_inner">
                    <div className={`status_dot ${label}`} />
                    <span className="title">{label}</span>
                  </div>
                  <span className="DigitalFarming_residential_status_container_status_item_count_percentage">
                    {count} ({compactNumberConverter(percentage, 'percent', 2)})
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
      <LensSidebar>
        <MaxHeightContainer
          fullHeight
          header={
            <LensSidebarHeader
              heading="Digital Farming"
              subHeading="Expand your marketing efforts by reaching out to prospects within new areas."
            />
          }
          footer={<></>}
        >
          <div className="DigitalFarming__search_location">Search a Location</div>
          <div className="LensSidebarHeader__sub-heading LensSidebarHeader__sub-heading__sub">
            e.g. City, Zip Code
          </div>
          <div className="DigitalFarming__sidebar-content">
            {locations.length < 1 ? <MapSearch onSelect={addLocation} /> : ''}
            {hasLocations && (
              <>
                {locations.length < 1 ? <Spacer height="2rem" /> : ''}
                <LocationLabels loading={loading} locations={locations} onRemove={resetStates} />
                {locations.length < 1 ? <Spacer height="2rem" /> : ''}
                <div className="DigitalFarming__sidebar-content__observation-filters">
                  <div className="DigitalFarming__sidebar-content__observation-filters__content">
                    <div className="DigitalFarming__sidebar-content__observation-filters__content__filter-inner">
                      <div className="filter">
                        <FontAwesomeIcon icon={faHome} color="#FFAB03" />
                        <span>Unlisted Residences</span>
                      </div>
                    </div>
                  </div>
                  <div className="divider" />
                  <Button
                    UNSAFE_className="MyListingsLens__FooterBtn"
                    kind="primary"
                    weight="bold"
                    size="l"
                    width="100%"
                    disabled={loading}
                    loading={loading}
                    onClick={onFindSellers}
                  >
                    Search
                  </Button>
                </div>
              </>
            )}
          </div>
        </MaxHeightContainer>
      </LensSidebar>
      {showProspectsModal() && !!totalProspects && (
        <LensSidebarFooter>
          <AudienceResultsCard>
            <div className="MyListingsLens__score">
              <div className="MyListingsLens__scoreLeft">
                <p className="prospects_count">~{totalProspects.toLocaleString()}</p>
              </div>
              <div className="MyListingsLens__scoreRight">
                <b className="prospects_observed">Unlisted Residences Found </b>
              </div>
            </div>
            {totalProspects === 0 ? (
              <div>
                <p className="no_footfall">No footfall traffic has been seen in this area.</p>
              </div>
            ) : null}
            <SaveAudienceWizard
              type="Digital Farming"
              dataObj={dataObj}
              onSaveAndClose={() => {
                resetStates();
              }}
              totalProspects={totalProspects}
              listing={features}
            />
          </AudienceResultsCard>
        </LensSidebarFooter>
      )}

      <div className="DigitalFarming__map">
        <Map
          type="Digital Farming"
          features={[]}
          locations={[]}
          polygon={polygonLocation}
          marker={f => <MapMarker color={getIconBuzzColor(f)} />}
          popup={f => <MapPopup feature={f} />}
          tooltip={f => <MapToolTip feature={f} />}
        />
      </div>
    </div>
  );
};

import React from 'react';

import Tippy from '@tippyjs/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import CustomIcons from 'assets/icons';

import type { APIFeatureType } from 'types';

import './ListingLabel.scss';

export const ListingLabel = ({
  listing,
  isSelected,
  onClick,
}: {
  listing: APIFeatureType;
  isSelected: boolean;
  onClick?: (clickedListing: APIFeatureType) => void;
}) => {
  return (
    <button
      type="button"
      className={`ListingLabel${isSelected ? ' ListingLabel--selected' : ''}`}
      onClick={() => listing && onClick && onClick(listing)}
    >
      <div className="ListingLabel__wrapper">
        <div className="ListingLabel__icon-circle">
          {isSelected && <FontAwesomeIcon icon={faCheck} />}
        </div>
        <div className="ListingLabel__labels">
          <Tippy
            className="audience-pending-tooltip listing-tooltip"
            placement="top-start"
            hideOnClick={false}
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-20, 10],
                  },
                },
              ],
            }}
            content={
              listing.status === 'Active'
                ? 'Just listed'
                : listing.status === 'Pending'
                ? 'Pending'
                : 'Just sold'
            }
          >
            <span className="ListingLabel__status">
              {listing.status === 'Active' ? (
                <CustomIcons name="home_sync" color="#52C41A" />
              ) : listing.status === 'Pending' ? (
                <CustomIcons name="home_pending" color="#AEAEAE" />
              ) : (
                <CustomIcons name="home_times" color="#FFAB03" />
              )}
            </span>
          </Tippy>
          <div className="ListingLabel__labels__info">
            <div className="ListingLabel__label" title={listing.street}>
              {listing.street}
            </div>
            <div className="ListingLabel__sub-label">
              {listing.city} {listing.state}, {listing.zip}
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

import React, { useMemo } from 'react';
import paligonSrc from 'assets/Images/paligon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { GuideEnum, RecommendationTypeEnum, RecommendationListingEnum } from 'enums';
import Contacts from './components/Contacts';
import Recommendations from './components/Recommendations';
import './styles.scss';

type LocationType = {
  state?: string;
  city?: string;
  street?: string;
  zip?: string;
};

interface Props {
  isActive: boolean;
  type: GuideEnum;
  collapseToggle: boolean;
  handleClose: () => void;
  recommendationsCount?: number;
  recommendationType?: RecommendationTypeEnum;
  recommendationListingName?: RecommendationListingEnum;
  locationData?: LocationType;
  sellersCount?: number;
  buyersCount?: number;
}

const GuidePopoverSelector = ({
  type,
  isActive,
  collapseToggle,
  handleClose,
  recommendationsCount,
  recommendationType,
  recommendationListingName,
  locationData,
  sellersCount,
  buyersCount,
}: Props) => {
  const address = [
    locationData?.street ?? '',
    locationData?.city ?? '',
    locationData?.state ?? '',
    locationData?.zip ?? '',
  ];
  const fullAddress = address.filter(addressValue => addressValue !== '').join(', ');
  const recommendationMSGbyType = useMemo(() => {
    switch (recommendationType) {
      case RecommendationTypeEnum.AGENT:
        if (sellersCount && (buyersCount === 0 || buyersCount === null)) {
          return `${sellersCount} of your contact households who are potential sellers are displaying in-market activity over the last 90 days.`;
        }

        if (buyersCount && (sellersCount === 0 || sellersCount === null)) {
          return `${buyersCount} of your contact households who are potential buyers are displaying in-market activity over the last 90 days`;
        }

        return `We found ${recommendationsCount} recommendations tailored to your needs, try to make the most of them!`;
      case RecommendationTypeEnum.LISTING:
        return recommendationListingName === RecommendationListingEnum.PROMOTION
          ? `Congrats on your new listing at ${fullAddress}! Engage with potential buyers seen at comparable properties.`
          : `Congrats on your new listing at ${fullAddress}! Engage with potential buyers residing around your listing area.`;
      case RecommendationTypeEnum.SOLD_LISTING:
        return `Congrats on closing the deal at ${fullAddress}! Engage with potential sellers residing around your listing area.`;
      default:
        return '';
    }
  }, [recommendationType, locationData, sellersCount, buyersCount]);

  const content = {
    [GuideEnum.CONTACTS]: <Contacts />,
    [GuideEnum.RECOMENDATIONS]: (
      <Recommendations
        isListing={
          recommendationType === RecommendationTypeEnum.LISTING ||
          recommendationType === RecommendationTypeEnum.SOLD_LISTING
        }
        recommendationsCount={Number(recommendationsCount)}
        msg={recommendationsCount === 1 ? recommendationMSGbyType : ''}
        btnText={
          recommendationsCount === 1 &&
          recommendationType === RecommendationTypeEnum.AGENT &&
          buyersCount &&
          sellersCount
            ? 'See recommendations'
            : ''
        }
      />
    ),
  };

  const position =
    type === GuideEnum.CONTACTS
      ? {
          top: 3.5,
          left: collapseToggle ? 18 : 5,
        }
      : {
          top: 12.1,
          left: collapseToggle ? 18 : 5,
        };

  return isActive && (recommendationsCount || type === GuideEnum.CONTACTS) ? (
    <div
      className="overlay_container"
      style={{ left: `${position.left}rem`, top: `${position.top}rem` }}
    >
      <FontAwesomeIcon
        icon={faTimes}
        color="#767676"
        size="lg"
        onClick={handleClose}
        style={{ position: 'absolute', right: '5%', top: '12%', cursor: 'pointer' }}
      />
      <img src={paligonSrc} className="paligon" alt="paligon" />
      {content[type]}
    </div>
  ) : null;
};

export default GuidePopoverSelector;

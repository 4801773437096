import { Button, Select, Text } from '@eltoro-ui/components';
import React, { useEffect, useState } from 'react';
import './FindYourProspectFilters.scss';

export type SelectOptionType = {
  label: string;
  value?: string;
};

type TT = {
  bedNBath: boolean;
  price: boolean;
  squareFoot: boolean;
  homeType: boolean;
};

export const PriceFilter = ({
  modalOpen,
  setIsModalOpen,
  setPrice,
  currentFilters,
  postedFilters,
  setPostedFilter,
  setIsClicked,
  isClicked,
}: {
  modalOpen: any;
  setIsModalOpen: any;
  // setIsModalOpen: (price: boolean) => void
  setPrice: any;
  currentFilters: any;
  postedFilters: any;
  setPostedFilter: any;
  setIsClicked?: any;
  isClicked?: any;
}) => {
  const [range, setRange] = useState<any>({ price: null });
  const [postRange, setPostRange] = useState<any>({
    price: null,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [windowInnerHeight, setWindowInnerHeight] = useState(0);

  useEffect(() => {
    setWindowInnerHeight(window.innerHeight);
  }, [window.innerHeight]);

  const arrOfPrice = [
    { label: 'Any' },
    { label: '$0 - $99,000' },
    { label: '$100,000 - $199,000' },
    { label: '$200,000 - $299,000' },
    { label: '$300,000 - $399,000' },
    { label: '$400,000 - $499,000' },
    { label: '$500,000 - $599,000' },
    { label: '$600,000 - $699,000' },
    { label: '$700,000 - $799,000' },
    { label: '$800,000 - $899,000' },
    { label: '$900,000 - $999,000' },
    { label: '$1,000,000 - $1,499,000' },
    { label: '$1,500,000 - $1,999,000' },
    { label: '$2,000,000 - $2,999,000' },
    { label: '$3,000,000 - $3,999,000' },
    { label: '$4,000,000 - $4,999,000' },
    { label: '$5,000,000 - $5,999,000' },
    { label: '$6,000,000 - $6,999,000' },
  ];

  const onClickCancelOrClear = (type: string) => {
    setIsModalOpen({ ...modalOpen, price: false });
    if (type === 'Clear') {
      setPostedFilter({
        ...postedFilters,
        price: null,
      });
      setPrice({
        ...currentFilters,
        price: null,
      });

      if (range?.price || currentFilters?.price) {
        setIsClicked({ ...isClicked, isLoaded: false });
      }
    }
    // show prospects box on cancel
    // else {
    //   setIsClicked({ ...isClicked, isLoaded: true })
    // }
  };

  const onSaveChanges = () => {
    setIsModalOpen({ ...modalOpen, price: false });
    setIsClicked({ ...isClicked, isLoaded: false });

    if (postRange.price) {
      setPostedFilter({
        ...postedFilters,
        price: postRange.price,
      });
      setPrice({
        ...currentFilters,
        price: range.price,
      });
    } else {
      setPostedFilter(postedFilters);
      setPrice(currentFilters);
    }
  };

  const onChange = (val: SelectOptionType | undefined) => {
    setRange({ ...range, price: val?.label });
    setPostRange({
      ...postRange,
      price: val?.label.replaceAll('$', '').replaceAll(',', '').replaceAll(' ', ''),
    });
  };

  useEffect(() => {
    if (range?.price || currentFilters?.price) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [range, currentFilters]);

  return (
    <div className="FindYourProspectFilters__priceFilterConatiner">
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Text size="m" tag="div" on="grey-500" UNSAFE_className="title">
          Price Range
        </Text>
        <div className="title-divider" />
      </div>
      <br />
      <div className="FindYourProspectFilters__priceFilter" style={{ marginBottom: '20px' }}>
        <Select
          width="315px"
          Height="38px"
          radius="10px"
          noCross
          isCenter
          size="18px"
          borderStyle="1px solid #E3E4E5"
          textColor="#000000"
          placeholder={range?.price || currentFilters?.price}
          options={arrOfPrice}
          selectedOption={range?.price}
          onChange={onChange}
          maxHeight={220}
        />
      </div>
      <hr />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          kind="default"
          size="l"
          UNSAFE_className="SellYourListingFaster__modalCancel"
          weight="bold"
          onClick={() => onClickCancelOrClear('Cancel')}
        >
          Cancel
        </Button>
        <Button
          kind="default"
          UNSAFE_className="SellYourListingFaster__modalSave"
          size="l"
          weight="bold"
          onClick={onSaveChanges}
          disabled={isDisabled}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

/* eslint-disable */
import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/free-solid-svg-icons';

import { Button, FlexBox, Modal, Spacer, Text } from '@eltoro-ui/components';
import { RadioButtonCustom } from '@eltoro-ui/components/RadioButtonCustom';
import { SummaryEditButton, SummaryTotal } from 'Components/SummaryCart';
import { CreditModal } from 'Components/CreditModal';
import AddCard from 'Pages/MyProfile/components/BillingSection/components/AddCard/AddCard';

import plusIcon from 'assets/Images/addcard.png';
import cross from 'assets/Images/cross.png';
import banner from 'assets/Images/Group870623.png';
import succesTick from 'assets/Images/Group87260.png';
import Lefticon from 'assets/Images/honeycombleft.png';
import Righticon from 'assets/Images/honeycombright.png';
import leftGroup from 'assets/Images/leftgroup.svg';
import rightGroup from 'assets/Images/rightgroup.svg';
import video from 'assets/Images/Subtract22.png';
import visacard from 'assets/Images/visacard.png';

import addcardbill from 'assets/Images/addcardbill.png';
import americanexpress from 'assets/Images/americanexpress.png';
import dinearclub from 'assets/Images/dinearclub.png';
import discover from 'assets/Images/discover.png';
import Jcb from 'assets/Images/Jcb.png';
import mastercard from 'assets/Images/mastercard.png';

import { MIN_IMPRESSIONS_COUNT } from 'Utils/constants';

import { getPaymentCards, postCampaignBudget } from 'Requests';

import type { TRootState } from 'types';

import './SummaryCart.scss';

type SummaryCartProps = {
  campaign: any;
  path: string;
  campaignId: string;
  checkout?: any;
  daysRemaining?: any;
};

export const SummaryCart: FC<SummaryCartProps> = ({
  campaign,
  path,
  campaignId,
  checkout,
  daysRemaining,
}) => {
  const { userData } = useSelector<TRootState, TRootState['userReducer']>(
    state => state.userReducer
  );

  const [creditModalOpen, setCreditModalOpen] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [isCreditBuy, setIsCreditBuy] = useState(false);
  const [checkoutLoaded, isCheckoutLoaded] = useState(false);
  const [isSelectCard, setIsSelectCard] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [seletedCardId, setSeletedCardId] = useState('');
  const [card, setCard] = useState<any>([]);

  const getImpressions = (): {
    banner_imp: number;
    video_imp: number;
    impressions: number;
  } => {
    const banner_imp = campaign?.banner_count ? 4 * checkout?.days * campaign?.prospects_counts : 0;
    const video_imp = campaign?.video_count ? 3 * checkout?.days * campaign?.prospects_counts : 0;
    const impressions =
      banner_imp && video_imp ? banner_imp + video_imp : banner_imp ? banner_imp : video_imp;
    return { impressions, banner_imp, video_imp };
  };
  const [needDays, setNeedDays] = useState<any>();
  const getFreq = () => {
    if (campaign?.video_count && campaign?.banner_count > 0) {
      return 4;
    }
    if (campaign?.video_count > 0) {
      return 3;
    }
    return 4;
  };
  const brand = (item: string) => {
    if (item === 'visa') {
      return visacard;
    }
    if (item === 'mastercard') {
      return mastercard;
    }
    if (item === 'discover') {
      return discover;
    }
    if (item === 'american_express') {
      return americanexpress;
    }
    if (item === 'jcb') {
      return Jcb;
    }
    if (item === 'diners_club') {
      return dinearclub;
    }
  };
  const getCardDetail = () => {
    getPaymentCards().then(res => {
      const data = res.data?.details;
      /* @ts-ignore */
      setCard(data);
      /* @ts-ignore */
      setSeletedCardId(res?.data?.details[0].id || '');
      setLoading(false);
    });
  };
  useEffect(() => {
    getCardDetail();
  }, []);

  useEffect(() => {
    const { banner_imp, video_imp } = getImpressions();
    if (banner_imp < MIN_IMPRESSIONS_COUNT || video_imp < MIN_IMPRESSIONS_COUNT) {
      const banner_calc = banner_imp
        ? MIN_IMPRESSIONS_COUNT / (campaign?.prospects_counts * 4) - checkout?.days
        : 0;
      const video_calc = video_imp
        ? MIN_IMPRESSIONS_COUNT / (campaign?.prospects_counts * 3) - checkout?.days
        : 0;

      const maxCalc = Math.max(banner_calc, video_calc);

      if (maxCalc > 0 && maxCalc < 1) {
        if (daysRemaining) {
          daysRemaining(1);
        }
        setNeedDays(1);
      } else {
        setNeedDays(maxCalc);
        if (daysRemaining) {
          daysRemaining(maxCalc);
        }
      }
    } else {
      setNeedDays(0);
      if (daysRemaining) {
        daysRemaining(0);
      }
    }
  }, [checkout?.days]);
  function bannerCost() {
    if (campaign?.banner_count >= 1) {
      const data = (campaign?.prospects_counts * checkout?.days * 4) / 1000;
      return data * 20;
    }
    return 0;
  }
  function mailerCost() {
    if (campaign?.direct_mail_count >= 1) {
      return 2;
    }
    return 0;
  }
  function videoCost() {
    if (campaign.video_count >= 1) {
      const data = (campaign?.prospects_counts * checkout?.days * 3) / 1000;
      return data * 30;
    }
    return 0;
  }
  function creditsPerMile(): any {
    if (!campaign?.banner_count && !campaign?.video_count) {
      return mailerCost();
    }
    return bannerCost() + videoCost();
  }
  function totalBudget() {
    if (campaign?.banner_count || campaign?.video_count) creditsPerMile();
    return creditsPerMile();
  }

  const diff = userData?.addons_credits - totalBudget();

  const History = useHistory();

  const postBudget = () => {
    isCheckoutLoaded(true);
    const { impressions, video_imp, banner_imp } = getImpressions() || {};

    const now = new Date();
    const startDate = new Date(checkout?.start_date).getTime();
    const ToDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();

    const start_date =
      startDate === ToDay
        ? new Date().toJSON()
        : `${checkout?.start_date.getFullYear()}-${
            checkout?.start_date.getMonth() + 1 < 10
              ? `0${checkout?.start_date.getMonth() + 1}`
              : checkout?.start_date.getMonth() + 1
          }-${
            checkout?.start_date.getDate() < 10
              ? `0${checkout?.start_date.getDate()}`
              : checkout?.start_date.getDate()
          }T00:00:00.000Z` || campaign?.start_date;

    const data = {
      start_date: start_date,
      end_date:
        `${checkout?.end_date.getFullYear()}-${
          checkout?.end_date.getMonth() + 1 < 10
            ? `0${checkout?.end_date.getMonth() + 1}`
            : checkout?.end_date.getMonth() + 1
        }-${
          checkout?.end_date.getDate() < 10
            ? `0${checkout?.end_date.getDate()}`
            : checkout?.end_date.getDate()
        }T00:00:00.000Z` || campaign?.end_date,
      days: checkout?.days || campaign?.date,
      budget: parseFloat(totalBudget().toFixed(2)),
      impressions,
      video_imp,
      banner_imp,
      impressions_perday: checkout?.frequency || campaign?.impressions_perday,
      payment_source_id: seletedCardId,
    };

    postCampaignBudget(campaign?.id, data)
      .then((res: any) => {
        if (res.detail) {
          toast.error(res.detail);
        }
        if (res?.data?.status === 'Creating') {
          setOpenSubmitModal(true);
        }
        isCheckoutLoaded(false);
      })
      .catch((err: any) => {
        toast.error(err.detail);
      });
  };

  const findCreatives = (creativeType: string) => {
    if (creativeType === 'banner') {
      return campaign?.creatives?.filter(
        (creat: { content_type: string }) => creat.content_type === creativeType
      );
    }
    return campaign?.creatives?.find(
      (creat: { content_type: string }) => creat.content_type === creativeType
    );
  };

  useEffect(() => {
    if (isCreditBuy) {
      toast.success('Credits has been added to your account');
      setIsCreditBuy(false);
    }
  }, [isCreditBuy]);

  return (
    <div className="SummaryCart">
      <FlexBox flexDirection="column" padding="1rem" backgroundColor="#FFFFFF">
        <Text size="xxl" on="white" weight="bold" UNSAFE_className="Summary_heading">
          Summary
        </Text>
        {/* <Text on="white" size="s">
          Donec id elit non porta gravida et eget metus
        </Text> */}
      </FlexBox>
      {campaign?.audiences || campaign?.audience_count >= 1 || campaign?.audiences?.length >= 1 ? (
        <>
          <div className="SummaryCart__header-item">
            <Text on="grey-100" weight="bold" UNSAFE_className="SummaryCart__save_audience">
              SAVED AUDIENCE
            </Text>
            {!path.endsWith('/setup') && (
              <SummaryEditButton
                onClick={() => {
                  History.replace(`/create-campaign/${campaignId}/setup`);
                }}
              >
                EDIT
              </SummaryEditButton>
            )}
          </div>
          <div className="SummaryCart__body-item">
            <Text UNSAFE_className="SummaryCart__body-item__overall_font" on="grey-050">
              <FontAwesomeIcon icon={faWalking} color="var(--color-secondary-300)" />
              <span className="SummaryCart__prospects_heading">
                {' '}
                {campaign?.prospects || campaign?.prospects_counts} Prospects
              </span>{' '}
              <span className="dash">-</span>
              <span className="SummaryCart__prospects_subheading">
                {' '}
                {campaign?.audience_count || campaign?.audiences}{' '}
                {`audience${campaign?.audience_count > 1 || campaign?.audiences > 1 ? 's' : ''}`}
              </span>
            </Text>
          </div>
        </>
      ) : (
        ''
      )}

      {openSubmitModal ? (
        <Modal>
          <div
            className="OnboardingModal-welcome SummaryCart__submitModal"
            style={{ position: 'relative' }}
          >
            <img className="SummaryCart__lefticon" src={Lefticon} alt="lefticon" />
            <div className="OnboardingModal-welcomeHeader SummaryCart__submitModalContent">
              <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
                <img src={succesTick} alt="succesTick" />
              </div>
              <div className="SummaryCart__title_container">
                <Text UNSAFE_className="SummaryCart__title" on="white" textAlign="center">
                  Your Campaign Is Submitted For Review
                </Text>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              <Button
                UNSAFE_style={{ padding: '30px 30px' }}
                kind="primary"
                size="xl"
                width="50%"
                weight="bold"
                onClick={() => {
                  setOpenSubmitModal(false);
                  History.push('/campaigns');
                }}
                UNSAFE_className="SummaryCart__Back_to_campaigns"
              >
                Back to campaigns
              </Button>
            </div>
            <img className="SummaryCart__rigticon" src={Righticon} alt="righticon" />
          </div>
        </Modal>
      ) : (
        ''
      )}
      {/* Creatives Type */}

      {campaign?.creatives?.length > 0 ||
      campaign?.banner_count ||
      campaign?.video_count ||
      campaign?.direct_mail_count ? (
        <>
          <div className="SummaryCart__header-item">
            <Text on="grey-100" weight="bold" UNSAFE_className="SummaryCart__save_audience">
              CREATIVE TYPE
            </Text>
            {!path.endsWith('/creatives') && (
              <SummaryEditButton
                onClick={() => History.replace(`/create-campaign/${campaignId}/creatives`)}
              >
                EDIT
              </SummaryEditButton>
            )}
          </div>
          <>
            {findCreatives('video') ? (
              <div className="SummaryCart__body-item ">
                <Text on="grey-050" UNSAFE_className="SummaryCart__content_heading">
                  <img src={video} alt="" className="SummaryCart__itemImage" />
                  Video
                </Text>
                <span className="SummaryCart__body-item-subheading">
                  This <b>video ad</b> will be served 3 times per day
                </span>
              </div>
            ) : (
              ''
            )}

            {findCreatives('banner')?.length !== 0 && (
              <div className="SummaryCart__body-item ">
                <Text UNSAFE_className="SummaryCart__content_heading" on="grey-050">
                  <img src={banner} alt="" className="SummaryCart__itemImage" />
                  Digital Banner{' '}
                  {findCreatives('banner')?.length > 1 && `(${findCreatives('banner')?.length})`}
                </Text>
                <span className="SummaryCart__body-item-subheading">
                  This <b>banner ad</b> will be served 4 times per day
                </span>
              </div>
            )}
          </>
        </>
      ) : (
        ''
      )}

      {(totalBudget() || campaign.budget) && checkout ? (
        <FlexBox flexDirection="column" padding="1rem" paddingBottom="0.5rem">
          <div className="SummaryCart__total_budget">
            <SummaryTotal total={totalBudget().toFixed(2) || campaign?.budget.toFixed(2)} />
          </div>
          <Spacer />
          <Button
            size="xl"
            kind="primary"
            width="100%"
            weight="bold"
            UNSAFE_className="checkout_and_submit"
            disabled={needDays > 0 || checkoutLoaded}
            loading={checkoutLoaded}
            onClick={() => setIsSelectCard(true)}
          >
            Checkout and Submit
          </Button>
          {isSelectCard && (
            <Modal style={{ position: 'relative' }} offClick={() => setIsSelectCard(false)}>
              <div className="SummaryCart__AddCardModal">
                <img className="SummaryCart__leftcardicon" src={leftGroup} alt="leftcardicon" />
                <img className="SummaryCart__righcardicon" src={rightGroup} alt="righcardicon" />
                <img
                  className="BillingSection__cross"
                  onClick={() => setIsSelectCard(false)}
                  src={cross}
                  alt="cross"
                />
                {/* @ts-ignore */}
                {/* <AddCard /> */}
                <div className="SummaryCart__allCardlist">
                  <Text on="white" UNSAFE_className="SummaryCart__addCardHeading">
                    {/* @ts-ignore */}
                    {!card.length ? 'Add Card' : 'Select Card'}
                  </Text>
                  {!card.length ? (
                    <div className="BillingSection__main">
                      <img
                        className="BillingSection__billingIcon"
                        src={addcardbill}
                        alt="addcardbill"
                      />
                      <Button
                        type="button"
                        kind="primary"
                        size="m"
                        weight="bold"
                        UNSAFE_className="BillingSection__billingButton"
                        onClick={() => {
                          setIsAddCard(true);
                        }}
                      >
                        Add Card
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className="SummaryCart__allCardlist_heading">
                        <div className="SummaryCart__heading_section">
                          <Text on="white" UNSAFE_className="SummaryCart__heading_text">
                            Card Detail
                          </Text>
                        </div>

                        <Text on="white" UNSAFE_className="SummaryCart__heading_text">
                          Expiry Date
                        </Text>
                      </div>
                      <div className="SummaryCart__card_list Modal__scrollable-content">
                        {card?.map((item: any) => (
                          <div
                            key={item.id}
                            className={
                              seletedCardId === item.id
                                ? 'SummaryCart__detailsCard_id'
                                : 'SummaryCart__detailsCard'
                            }
                          >
                            <div className="SummaryCart__billing_card">
                              <RadioButtonCustom
                                isCircular
                                checked={seletedCardId === item.id}
                                onChange={checked => setSeletedCardId(item.id)}
                              />
                              <img
                                className="SummaryCart__billing_card_brand-icon"
                                src={brand(item?.card.brand)}
                                alt={item?.card?.brand}
                              />
                              <div className="SummaryCart__billing_card_left">
                                <Text on="white" UNSAFE_className="SummaryCart__card_detail_text">
                                  {item?.card?.masked_number}
                                </Text>
                                <Text on="white" UNSAFE_className="SummaryCart__card_detail_text">
                                  {`${item?.card?.first_name} ${item?.card?.last_name}`}
                                </Text>
                              </div>
                            </div>
                            <div className="">
                              <Text on="white" UNSAFE_className="SummaryCart__heading_text_detail">
                                {`${item?.card?.expiry_month}/${item?.card?.expiry_year}`}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div
                        className="SummaryCart__addCard"
                        onClick={() => {
                          setIsAddCard(true);
                          setIsSelectCard(false);
                        }}
                      >
                        <button className="SummaryCart__headingButton" type="button">
                          <img src={plusIcon} alt="uploadicon" style={{ marginRight: '10px' }} />{' '}
                          Add Card
                        </button>
                      </div>
                      <Button
                        kind="primary"
                        width="30%"
                        weight="bold"
                        UNSAFE_style={{
                          margin: '1.5rem auto 0 auto',
                        }}
                        UNSAFE_className="summary_card_done"
                        onClick={() => {
                          postBudget();
                          setIsSelectCard(false);
                        }}
                      >
                        Done
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Modal>
          )}
          {isAddCard && (
            <Modal style={{ position: 'relative' }}>
              <div className="BillingSection__AddCardModal">
                <img
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    right: '0px',
                    width: '29%',
                  }}
                  src={rightGroup}
                  alt="confirmleftcomb"
                />

                <img
                  className="BillingSection__cross"
                  onClick={() => {
                    setIsAddCard(false);
                    setIsSelectCard(true);
                  }}
                  src={cross}
                  alt="cross"
                />
                {/* @ts-ignore */}
                <div className="SummaryCart__campaginCard">
                  <p className="addCardHeading " style={{ marginBottom: '10px' }}>
                    Add Card
                  </p>

                  <AddCard
                    /* @ts-ignore */
                    getCardDetail={getCardDetail}
                    setIsAddCard={setIsAddCard}
                    setLoadings={setLoading}
                    setIsSelectCard={setIsSelectCard}
                  />
                </div>
                <img
                  style={{
                    position: 'absolute',
                    top: '-20px',
                    left: '8px',
                    width: '28%',
                  }}
                  src={leftGroup}
                  alt="lconfirmleftcomb"
                />
              </div>
            </Modal>
          )}

          {checkoutLoaded && (
            <span
              style={{
                color: '#DC9300',
                marginTop: '8px',
                fontSize: '17px',
                fontWeight: 400,
                textAlign: 'center',
              }}
            >
              The campaign is in progress to be created.
            </span>
          )}
          <Spacer />
        </FlexBox>
      ) : (
        ''
      )}

      {creditModalOpen && (
        <CreditModal
          offClick={() => setCreditModalOpen(false)}
          pointsNeeded={diff < 1 ? diff : 0}
          setIsCreditBuy={setIsCreditBuy}
        />
      )}
    </div>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import './CreativeGen.scss';
import './theme.css';
import { Button, FlexBox, GridBox, LoadingBar, Modal, Spacer, Tabs } from '@eltoro-ui/components';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImages,
  faLaptop,
  faPaintRoller,
  faRedo,
  faSave,
  faThLarge,
  faTimes,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { RecoilRoot, Snapshot, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useResizeObserver from '@react-hook/resize-observer';
import { useTimeTravel, useUnsplash } from './Hooks';
import {
  // dataUrlToFile,
  handleZoom,
} from './Helpers';
// import { trackDownload } from 'Requests'
import { CreativeStage } from './Components/Canvas';
import {
  BackgroundImageTab,
  BlockSelection,
  DirectMailSizeSelector,
  EditBlock,
  FilterTab,
  Sidebar,
  ZoomSettings,
} from './Components/UI';
import {
  backgroundPhotoAtom,
  creativeTypeAtom,
  directMailSizeAtom,
  loadingBackgroundPhotoAtom,
  selectedKindAtom,
} from './State';
import cross from '../../assets/Images/cross.png';

// CreativeBody is wrapped below by CreativeGen which sets its height and width and supplies the Recoil context
const CreativeBody: React.FC<{
  snapshot?: Snapshot;
  onComplete: (base64s: string[], present: any) => void;
  defaultCreativeType: 'banner';
  onClose: () => void;
}> = ({ snapshot, onComplete, defaultCreativeType, onClose }) => {
  const { undo, canUndo, redo, canRedo, present } = useTimeTravel(snapshot);
  const [creativeType, setCreativeType] = useRecoilState(creativeTypeAtom);
  const directMailSize = useRecoilValue(directMailSizeAtom);
  const [stageDims, setStageDims] = useState({ width: 0, height: 0 });
  const creativeStageRef = useRef<HTMLDivElement>(null);
  const stageRef = useRef<Konva.Stage>(null);
  const [mainGroupLocation, setMainGroupLocation] = useState({ x: 0, y: 0 });
  const [showEditPanel, setShowEditPanel] = useState(false);
  const [zoom, setZoom] = useState({ x: 1, y: 1 });
  const backgroundPhoto = useRecoilValue(backgroundPhotoAtom);
  const [loading, setLoading] = useRecoilState(loadingBackgroundPhotoAtom);
  const setSelectedKind = useSetRecoilState(selectedKindAtom);
  const [tab, setTab] = useState('');
  const handleSelectTab = (selection: string) => {
    setTab(selection === tab ? '' : selection);
  };
  // gets dimensions of stage
  useResizeObserver(creativeStageRef, entry => setStageDims(entry.contentRect));

  const { search, handleSearch } = useUnsplash();

  const adRefs = useRef<Konva.Group[]>([]);
  const addToRefs = (el: Konva.Group) => {
    if (el && !adRefs.current.includes(el)) {
      adRefs.current.push(el);
    }
  };
  useEffect(() => {
    // if user changes creativeType (tabs) or direct mail size...
    // ...reset adRef array. Without this the array will...
    // ...continue to grow everytime a new board is rendered.
    adRefs.current = [];
  }, [creativeType, directMailSize]);

  const handleDataURL = (
    currentRef: Konva.Group,
    width: number,
    height: number,
    x: number,
    y: number
  ) => {
    return currentRef.toDataURL({
      mimeType: 'image/jpeg',
      width,
      height,
      x,
      y,
      quality: 1,
      // creates larger jpeg when printing...
      // ...exact ratio will need to be updated when more info comes from printhouse.
      pixelRatio: creativeType === 'directMail' ? 8 : 1,
    });
  };

  const handleSave = () => {
    setLoading(() => {
      setTimeout(() => {
        const results: any[] = [];
        adRefs.current.forEach(async currentRef => {
          const width = currentRef.width();
          const height = currentRef.height();
          const { x, y } = currentRef.absolutePosition();

          // const file = await dataUrlToFile(handleDataURL(currentRef, width, height, x, y));

          results.push({
            handleDataURL: handleDataURL(currentRef, width, height, x, y),
            width,
            height,
          });
        });
        // call unsplash download to satisfy API required download tracking
        if (backgroundPhoto) {
          // trackDownload(photo.links.download_location)
        }
        onComplete(results, 'present');
      });
      return true;
    });
  };

  // const handleZoomSet = (e: KonvaEventObject<WheelEvent>) => {
  //   if (!stageRef.current) return;
  //   setZoom(handleZoom(e, stageRef.current));
  // };

  const resetMainGroupLocation = () => {
    setMainGroupLocation({
      x: -stageDims.width / 2,
      y: -stageDims.height / 2,
    });
  };

  // if componet size updates for any reason...
  // ...reset main group location to make sure it doesnt fall out of bounds
  useEffect(() => {
    resetMainGroupLocation();
  }, [stageDims]);

  const handleTabChange = (type: 'banner' | 'directMail') => {
    setCreativeType(type);
    resetMainGroupLocation();
    setSelectedKind(undefined);
    setShowEditPanel(false);
  };

  useEffect(() => {
    handleTabChange(defaultCreativeType);
  }, [defaultCreativeType]);
  const [tabsList, settabsList] = useState([
    {
      id: 'banner',
      label: (
        <FlexBox>
          <FontAwesomeIcon icon={faLaptop} />
          <Spacer width="0.5rem" />
          Banner
        </FlexBox>
      ),
      onClick: () => handleTabChange('banner'),
      defaultTab: defaultCreativeType === 'banner',
    },
  ]);

  return (
    <div className="CreativeBody">
      <div style={{ display: 'flex', justifyContent: 'right', zIndex: 2 }}>
        <img
          src={cross}
          onClick={onClose}
          alt="cross"
          className="PersonalInfoForm__cross"
          role="presentation"
        />
      </div>

      <div className="CreativeBody__header">
        <Tabs tabs={tabsList} on="white" />
        {creativeType === 'directMail' && <DirectMailSizeSelector />}
      </div>
      <div className="CreativeBody__body">
        <Sidebar
          elements={
            <GridBox gap="0.25rem">
              <Button
                kind={tab === 'image' ? 'default' : 'text'}
                iconLeft={<FontAwesomeIcon icon={faImages} />}
                onClick={() => handleSelectTab('image')}
              />
              <Button
                kind={tab === 'filter' ? 'default' : 'text'}
                iconLeft={<FontAwesomeIcon icon={faPaintRoller} />}
                onClick={() => handleSelectTab('filter')}
              />
              <Button
                kind={tab === 'blocks' ? 'default' : 'text'}
                iconLeft={<FontAwesomeIcon icon={faThLarge} />}
                onClick={() => handleSelectTab('blocks')}
              />
            </GridBox>
          }
          footer={
            <>
              {/* {stageRef.current && ( */}
              {/*  <ZoomSettings */}
              {/*    windowDims={stageDims} */}
              {/*    stage={stageRef.current} */}
              {/*    zoom={zoom} */}
              {/*    onChange={setZoom} */}
              {/*    onPositionReset={resetMainGroupLocation} */}
              {/*  /> */}
              {/* )} */}
              <Spacer height="2rem" />
              <Button
                disabled={!canUndo}
                kind="text"
                iconLeft={<FontAwesomeIcon icon={faUndo} />}
                onClick={undo}
              />
              <Spacer height="0.25rem" />
              <Button
                disabled={!canRedo}
                kind="text"
                iconLeft={<FontAwesomeIcon icon={faRedo} />}
                onClick={redo}
              />
              <Spacer height="2rem" />
              <Button kind="text" iconLeft={<FontAwesomeIcon icon={faTimes} />} onClick={onClose} />
              <Spacer />
              <Button
                kind="primary"
                iconLeft={<FontAwesomeIcon icon={faSave} />}
                onClick={handleSave}
                disabled={loading}
              />
            </>
          }
        >
          {tab === 'image' && <BackgroundImageTab search={search} onChange={handleSearch} />}
          {tab === 'filter' && <FilterTab />}
          {tab === 'blocks' && <BlockSelection />}
        </Sidebar>
        <div ref={creativeStageRef} className="CreativeBody__stage">
          <CreativeStage
            stageRef={stageRef}
            isEditing={showEditPanel}
            onSelectBlock={setShowEditPanel}
            addToRefs={addToRefs}
            stageDims={stageDims}
            // onZoom={handleZoomSet}
            groupLocation={mainGroupLocation}
            onGroupDragEnd={setMainGroupLocation}
          />
        </div>
        {showEditPanel && (
          <Sidebar elements={<EditBlock onClose={() => setShowEditPanel(false)} />} side="right" />
        )}
      </div>
      {loading && (
        <Modal>
          <div className="CreativeBody__loading">
            <LoadingBar colorKind="tertiary" />
          </div>
        </Modal>
      )}
    </div>
  );
};

type CreativeGenProps = {
  snapshot?: Snapshot;
  onComplete: (base64s: any[], present: any) => void;
  defaultCreativeType: 'banner';
  onClose: () => void;
  width: string;
  height: string;
};

export const CreativeGen: React.FC<CreativeGenProps> = ({
  snapshot,
  onComplete,
  defaultCreativeType,
  onClose,
  width,
  height,
}) => {
  return (
    <RecoilRoot>
      <div className="CreativeGen" style={{ height, width }}>
        <CreativeBody
          snapshot={snapshot}
          onComplete={onComplete}
          defaultCreativeType={defaultCreativeType}
          onClose={onClose}
        />
      </div>
    </RecoilRoot>
  );
};

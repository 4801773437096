import React, { memo, useState } from 'react';
import { Button, FlexBox, Text } from '@eltoro-ui/components';
import notActiveImgSrc from 'assets/Images/not-active-contacts.svg';
import sellerImgSrc from 'assets/Images/sellers.svg';
import buyerImgSrc from 'assets/Images/buyers.svg';
import { RecommendationContactEnum } from 'enums';
import './styles.scss';
import { saveContactsAudience } from 'Requests/Request_Methods/contactsMethods';

interface Props {
  hasActiveContacts: boolean;
  kind: RecommendationContactEnum;
  recommendationId?: string;
}

const ActivityByKind = ({ hasActiveContacts, kind, recommendationId }: Props) => {
  const [audienceCreationLoading, setAudienceCreationLoading] = useState(false);
  const imgSrc = hasActiveContacts
    ? kind === RecommendationContactEnum.SELLER
      ? sellerImgSrc
      : buyerImgSrc
    : notActiveImgSrc;

  const desc = hasActiveContacts
    ? `Some of the potential ${
        kind === RecommendationContactEnum.SELLER ? 'seller' : 'buyer'
      } households in your contacts were seen in market in `
    : `None of your ${
        kind === RecommendationContactEnum.SELLER ? 'seller' : 'buyer'
      } contacts were seen in market in `;

  const btnText =
    kind === RecommendationContactEnum.SELLER ? 'Talk to likely sellers' : 'Talk to likely buyers';
  const handleAudienceCreationLoading = (isLoading: boolean) => {
    setAudienceCreationLoading(isLoading);
  };
  const handleClick = () => {
    handleAudienceCreationLoading(true);
    saveContactsAudience({
      recommendation_id: recommendationId,
      contact_type: kind === RecommendationContactEnum.BUYER ? 'buyer' : 'seller',
      tags: [],
    })
      .then(res => {
        if (res.data && res.data.campaign_id) {
          window.location.pathname = `/create-campaign/${res.data?.campaign_id}/setup`;
        }
      })
      .finally(() => {
        handleAudienceCreationLoading(false);
      });
  };

  return (
    <div className="activity_container">
      <FlexBox flexDirection="column" alignItems="center">
        <img src={imgSrc} alt="not active contacts" />
        <Text on="white" UNSAFE_className="desc" UNSAFE_style={{ width: '300px' }}>
          {desc}
        </Text>
        <Text on="white" UNSAFE_className="desc" UNSAFE_style={{ marginBottom: '1rem' }}>
          last 90 days
        </Text>
        <Button
          size="l"
          kind="primary"
          weight="bold"
          UNSAFE_className="save_or_launch_btn"
          onClick={handleClick}
          disabled={!hasActiveContacts}
          loading={audienceCreationLoading}
        >
          {btnText}
        </Button>
      </FlexBox>
    </div>
  );
};

export default memo(ActivityByKind);

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';

import moment from 'moment';

import toast from 'react-hot-toast';

import { Button, MaxHeightContainer, Spacer } from '@eltoro-ui/components';
import {
  ImpressionValidator,
  Layout,
  Loading,
  PageHeader,
  ProgressStepper,
  SummaryCart,
} from 'Components';
import {
  CampaignCreationFooter,
  CampaignCreationWrapper,
  DateRangeSelector,
} from 'Pages/CampaignCreation/components';
import type { CampaignSetupParams } from 'Pages/CampaignCreation/components';
import CampaignErrorInfo from 'Pages/CampaignCreation/components/CampaignErrorInfo';

import { getCampaignById } from 'Requests/Request_Methods/campaignMethods';

import type { CampaignType } from 'types';
import { CampaignStatusEnum, CampaignStep } from 'enums';

import './CampaignCheckout.scss';

export const CampaignCheckout = (props: RouteComponentProps<CampaignSetupParams>) => {
  const { campaignId } = props.match.params;
  const history = useHistory();

  const [freq, setFreq] = useState(3);
  const [campaign, setCampaign] = useState<CampaignType | null>(null);
  const [remainingdays, setRemainingDays] = useState<number>();

  useEffect(() => {
    const getCampaign = async () => {
      try {
        const { data } = await getCampaignById(campaignId);
        if (data) {
          if (data.status === CampaignStatusEnum.IN_REVIEW)
            history.replace(`/campaigns/${data.id}`);

          if (data.step === CampaignStep.DONE)
            if (
              data.status === CampaignStatusEnum.ERROR ||
              data.status === CampaignStatusEnum.CREATING
            )
              history.replace('/campaigns');

          setCampaign(data);
        }
      } catch (e) {
        toast.error(e);
      }
    };
    getCampaign();
    return () => {
      setCampaign(null);
    };
  }, []);

  const now = new Date();
  const [startDate, setStartDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate())
  );
  const [endDate, setEndDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate() + 14)
  );
  // const incCampaignFrequency = () => {
  //   setFreq(freq + 1)
  // }
  // const decCampaignFrequency = () => {
  //   if (freq > 1) setFreq(freq - 1)
  // }
  const days = moment(endDate).add({ days: 1 }).diff(moment(startDate), 'days');

  const checkout = {
    days: days || campaign?.days,
    frequency: freq || campaign?.impressions_perday,
    start_date: startDate,
    end_date: endDate,
  };
  return (
    <div className="CampaignCheckout">
      <MaxHeightContainer
        fullHeight
        header={
          <div>
            <PageHeader
              title={campaign?.name}
              UNSAFE_CLASSNAME="CampaignSetup__header"
              UNSAFE_CLASSNAME_GRID="CampaignSetup__titleSpace"
              UNSAFE_CLASSNAME_TITLE="CampaignCheckout__title"
              subTitle=" "
              actions={[
                <ProgressStepper
                  key="progress-stepper"
                  tagNames={['Audience', 'Creative', 'Checkout', 'Submit']}
                  activeStep={3}
                />,
              ]}
              actionsStyle="CampaignSetup__progress"
            />
          </div>
        }
        footer={
          <CampaignCreationFooter>
            <Button
              size="l"
              UNSAFE_className="campaignBack"
              onClick={() => {
                history.replace(`/create-campaign/${campaign?.id}/creatives`);
              }}
            >
              Back
            </Button>
            <Spacer />
          </CampaignCreationFooter>
        }
      >
        <Layout>
          {campaign?.status === CampaignStatusEnum.PAYMENT_FAILED &&
            campaign?.step === CampaignStep.CHECKOUT && (
              <CampaignErrorInfo
                title="There were some issues with the billing and checkout process."
                subTitle="Please verify your billing information and try again."
              />
            )}
          <CampaignCreationWrapper>
            <div className="CampaignCheckout__content">
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={setStartDate}
                onEndDateChange={setEndDate}
                message={(duration: any) => {
                  return remainingdays ? (
                    !!remainingdays && remainingdays > 0 && (
                      <>
                        <p className="CampaignCheckout__add_display">
                          {' '}
                          Your ads will display for these {duration}{' '}
                          {duration <= 1 ? 'day' : 'days'}
                        </p>
                        <br />
                        <ImpressionValidator days={Math.round(remainingdays)} />
                      </>
                    )
                  ) : (
                    <p className="CampaignCheckout__add_display">
                      {' '}
                      Your ads will display for these {duration} {duration <= 1 ? 'day' : 'days'}
                    </p>
                  );
                }}
              />
            </div>
            {campaign ? (
              <SummaryCart
                campaignId={campaignId}
                path={props.match.path}
                campaign={campaign}
                checkout={checkout}
                daysRemaining={setRemainingDays}
              />
            ) : (
              <Loading />
            )}
          </CampaignCreationWrapper>
        </Layout>
      </MaxHeightContainer>
    </div>
  );
};

import React from 'react';
import emptyImgSrc from 'assets/Images/recommendations-empty.svg';
import { FlexBox, Text } from '@eltoro-ui/components';
import './styles.scss';

const EmptyState = () => {
  return (
    <div className="empty_state_container">
      <FlexBox flexDirection="column" alignItems="center">
        <img src={emptyImgSrc} alt="empty" />
        <Text on="white" weight="bold" UNSAFE_className="title">
          You do not have recommendations yet!
        </Text>
        <Text on="white" UNSAFE_className="desc">
          Your personalized recommendations will be displayed here shortly.
        </Text>
      </FlexBox>
    </div>
  );
};

export default EmptyState;

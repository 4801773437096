import React from 'react';
import { RecommendationContactEnum } from 'enums';
import { FlexBox } from '@eltoro-ui/components';
import EmptyContacts from './EmptyContacts';
import Processing from './Processing';
import NotActive from './NotActive';
import ActivityByKind from './ActivityByKind';

interface Props {
  buyersCount?: number;
  sellersCount?: number;
  isEmpty?: boolean;
  isImporting?: boolean;
  isAnalizing?: boolean;
  hasActivity?: boolean;
  hasOnlyBuyer?: boolean;
  hasOnlySeller?: boolean;
  recommendations: any;
}

const ActivityContent = ({
  isEmpty,
  isImporting,
  isAnalizing,
  hasActivity,
  sellersCount,
  buyersCount,
  hasOnlyBuyer,
  hasOnlySeller,
  recommendations,
}: Props) => {
  let content;

  const recommendationIdForBuyer = recommendations?.find(
    (rec: any) => rec?.sub_type === 'buyer'
  )?.recommendation_id;
  const recommendationIdForSeller = recommendations?.find(
    (rec: any) => rec?.sub_type === 'seller'
  )?.recommendation_id;

  if (isEmpty) {
    content = <EmptyContacts />;
  }

  if (isImporting) {
    content = <Processing text="We are importing your contacts!" />;
  }

  if (isAnalizing) {
    content = <Processing text="We are still analyzing in-market activity of your contacts!" />;
  }

  if (!hasActivity && !isEmpty && !isAnalizing && !isImporting) {
    content = <NotActive />;
  }
  if (!isEmpty && !isAnalizing && !isImporting && hasActivity) {
    if (hasOnlyBuyer) {
      content = (
        <ActivityByKind
          key={RecommendationContactEnum.BUYER}
          hasActiveContacts={!!buyersCount}
          kind={RecommendationContactEnum.BUYER}
          recommendationId={recommendationIdForBuyer}
        />
      );
    }
    if (hasOnlySeller) {
      content = (
        <ActivityByKind
          key={RecommendationContactEnum.SELLER}
          hasActiveContacts={!!sellersCount}
          kind={RecommendationContactEnum.SELLER}
          recommendationId={recommendationIdForSeller}
        />
      );
    }

    if (!hasOnlySeller && !hasOnlyBuyer) {
      content = [
        <ActivityByKind
          key={RecommendationContactEnum.BUYER}
          hasActiveContacts={!!buyersCount}
          kind={RecommendationContactEnum.BUYER}
          recommendationId={recommendationIdForBuyer}
        />,
        <ActivityByKind
          key={RecommendationContactEnum.SELLER}
          hasActiveContacts={!!sellersCount}
          kind={RecommendationContactEnum.SELLER}
          recommendationId={recommendationIdForSeller}
        />,
      ];
    }
  }
  return (
    <FlexBox
      alignItems="center"
      justifyContent="center"
      UNSAFE_className="activity_by_kind"
      gap="60px"
    >
      {content}
    </FlexBox>
  );
};

export default ActivityContent;

import React from 'react';
import { Text } from '@eltoro-ui/components';

interface Props {
  label: string;
}

const DateLabel = ({ label }: Props) => {
  return (
    <Text on="white" weight="bold" UNSAFE_style={{ color: '#9B9B9B', fontSize: '16px' }}>
      {label}
    </Text>
  );
};

export default DateLabel;

import React, { useState } from 'react';
import { FlexBox, Text, Button } from '@eltoro-ui/components';
import CloudSpongeWidget from 'Pages/Dashboard/_Components/contactsGetter/CloudSpongeWidget';
import { uploadContactsSync } from 'Requests/Request_Methods/contactsMethods';
import toast from 'react-hot-toast';
import { UploadCSVModal } from 'Pages/MyContacts/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import emptyFolderImgSrc from 'assets/Images/empty-folder-contacts.svg';
import './styles.scss';
import { getUserRecommendations } from 'Requests/Request_Methods/userMethods';
import { TRootState } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { setRecommendations } from 'Redux/actions';

const EmptyContacts = () => {
  const [syncIsLoading, setSyncIsLoading] = useState(false);
  const [buttonActive, setButtonActive] = useState(true);
  const [loadFirst, setloadFirst] = useState(true);
  const [uploadCSVModalIsOpen, setUploadCSVModalIsOpen] = useState(false);
  const { userData } = useSelector((state: TRootState) => state.userReducer);
  const dispatch = useDispatch();

  const fetchRecommendations = async () => {
    const recommendationsResp = await getUserRecommendations(userData.id);
    dispatch(setRecommendations(recommendationsResp.data));
  };

  const afterSubmitContacts = async (cloudContacts: any) => {
    setSyncIsLoading(true);
    try {
      const contactsBody = cloudContacts.map((contact: any) => ({
        first_name: contact.first_name,
        last_name: contact.last_name,
        phone: contact.phone.map((phone: any) => ({
          number: phone.number,
          primary: phone?.primary,
          selected: phone?.selected,
        })),
        email: contact.email.map((email: any) => ({
          address: email.address,
          primary: email?.primary,
          selected: email?.selected,
        })),
        address: contact.address.map((address: any) => ({
          street: address.street,
          city: address.city,
          region: address.region,
          postal_code: address.postal_code,
        })),
      }));
      await uploadContactsSync(contactsBody);
      await fetchRecommendations();
      setSyncIsLoading(false);
    } catch (e) {
      setSyncIsLoading(false);
      toast.error('Oops, there was an issue with importing your contacts.');
    }
  };

  const launchCloudSponge = () => {
    // @ts-ignore
    if (window.cloudsponge) {
      // @ts-ignore
      window.cloudsponge.launch();
    }
  };

  const handleCSVUpload = () => {
    fetchRecommendations();
    setUploadCSVModalIsOpen(false);
  };

  return (
    <div className="activity_container">
      <FlexBox flexDirection="column" alignItems="center">
        <img src={emptyFolderImgSrc} alt="not active contacts" />
        <Text on="white" weight="bold" UNSAFE_style={{ marginBottom: '1rem' }}>
          You still do not have contacts imported.
        </Text>
        <FlexBox alignItems="center" justifyContent="center" gap="20px">
          <Button
            kind="default"
            size="l"
            UNSAFE_className="contacts_btn"
            weight="bold"
            onClick={() => setUploadCSVModalIsOpen(true)}
          >
            Upload Contacts
          </Button>
          <Button
            kind="default"
            size="l"
            weight="bold"
            UNSAFE_className="contacts_btn"
            onClick={launchCloudSponge}
            iconLeft={
              buttonActive || syncIsLoading ? (
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  style={{ fontSize: 20, animation: 'spinner-border 0.75s linear infinite' }}
                />
              ) : (
                <FontAwesomeIcon icon={faSync} style={{ fontSize: 20 }} />
              )
            }
          >
            Sync Email Accounts
          </Button>
        </FlexBox>
      </FlexBox>
      <CloudSpongeWidget
        afterSubmitContacts={afterSubmitContacts}
        setButtonActive={setButtonActive}
        loadFirst={loadFirst}
        buttonActive={buttonActive}
        onCloseTypea={() => {
          setloadFirst(false);
        }}
      />
      {uploadCSVModalIsOpen && <UploadCSVModal onClose={handleCSVUpload} />}
    </div>
  );
};

export default EmptyContacts;

import React from 'react';
import { FlexBox, Text } from '@eltoro-ui/components';

const NewListingPromotion = () => {
  return (
    <FlexBox
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      UNSAFE_className="card_content"
    >
      <FlexBox alignItems="center" justifyContent="space-between" UNSAFE_style={{ width: '100%' }}>
        <FlexBox
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          UNSAFE_className="heading"
        >
          <Text on="white" weight="bold" UNSAFE_className="title">
            New Listing Promotion
          </Text>
          <Text on="white" UNSAFE_className="desc">
            Beewo alerts you when a new listing of yours is detected in market, so that you can sell
            it faster.
          </Text>
        </FlexBox>
      </FlexBox>
      {/* <FlexBox justifyContent="center" alignItems="center" UNSAFE_className="library_card_content"> */}
      {/*  <Button size="l" disabled kind="primary" weight="bold"> */}
      {/*    Explore Your New Listings */}
      {/*  </Button> */}
      {/* </FlexBox> */}
    </FlexBox>
  );
};

export default NewListingPromotion;

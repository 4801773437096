import React from 'react';
import mobile from 'assets/Images/mobileRedirect.svg';
import { FlexBox, Text } from '@eltoro-ui/components';

const RedirectToMobileInfo = () => {
  return (
    <FlexBox
      UNSAFE_style={{
        background: '#fff',
        border: '1px solid #C1C1C1',
        borderRadius: '8px',
        padding: '24px 16px',
      }}
      alignItems="center"
    >
      <img src={mobile} alt="Sell Your Listing Faster" />
      <FlexBox flexDirection="column" gap="8px">
        <Text on="white" size="m">
          Have contacts on your phone? Easily import your mobile contacts through the Beewo App!
        </Text>
        <Text on="white" size="m" UNSAFE_style={{ color: '#9A9A9A' }}>
          Don’t have Beewo App?{' '}
          <a
            style={{
              color: '#007B94',
              textDecoration: 'underline',
              fontWeight: 700,
            }}
            href="https://forms.gle/4tfN5iT7u57aTTHKA"
            target="_blank"
            rel="noreferrer"
          >
            Request Access
          </a>
        </Text>
      </FlexBox>
    </FlexBox>
  );
};
export default RedirectToMobileInfo;

import React, { useCallback, useState } from 'react';
import type { HTMLAttributes } from 'react';

import classNames from 'classnames';
import Papa from 'papaparse';
import toast from 'react-hot-toast';

import { useDropzone } from 'react-dropzone';
import type { FileRejection } from 'react-dropzone';
import type { FileType, FileUploadType } from 'types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTimes, faVideo } from '@fortawesome/free-solid-svg-icons';

import warning_icon from 'assets/Images/warning_icon.svg';
import excelPng from 'assets/Images/excel.png';
import csvPng from 'assets/Images/csv.png';

import { formatBytes, formatVideoDuration } from 'Utils/helpers';

import './Uploader.scss';

type UploaderType = {
  onDropFile: (files: FileUploadType) => void;

  fileSubmited?: any;
  type: 'banner' | 'directMail' | 'video' | 'csv';
  multiple?: boolean;
  validate?: boolean;
  showThumbs?: boolean;
  areaWrapperClassname?: HTMLAttributes<HTMLDivElement>['className'];
};

const mimeTypes = {
  banner: {
    'image/*': ['.jpeg', '.gif', '.png', '.jpg'],
  },
  directMail: {
    'image/*': ['.jpeg', '.png'],
  },
  video: {
    'video/*': ['.mp4', '.mov'],
    // '.avi', '.mpg', '.mpeg'
  },
  csv: {
    'text/*': ['.csv'],
  },
};

const videoBrowserMimetypes = ['quicktime'];
const csvBrowserMimetypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const ImageDimensions = ['300x250', '320x100', '728x90', '320x50', '300x600', '160x600'];
const VideoDimensions = ['400x225', '400x300', '480x360', '640x360', '1920x800', '1920x1080'];

const maxSize = {
  banner: 153600,
  directMail: 1000000,
  video: 104857600,
  csv: 500000000000,
};

const maxFiles = {
  banner: 12,
  directMail: 0,
  video: 5,
  csv: 0,
};

const thumbIcon = {
  banner: <FontAwesomeIcon icon={faImage} color="#F6B433" style={{ fontSize: 25 }} />,
  video: <FontAwesomeIcon icon={faVideo} color="#F6B433" style={{ fontSize: 25 }} />,
  directMail: <FontAwesomeIcon icon={faImage} />,
  csv: <img src={csvPng} alt="csv" className="thumb-img-icon" />,
  exel: <img src={excelPng} alt="exel" className="thumb-img-icon" />,
};

const getFileThumbIcon = (type: UploaderType['type'], name: string) => {
  if (type === 'banner') return thumbIcon.banner;
  if (type === 'video') return thumbIcon.video;

  if (type === 'csv') {
    if (name.split('.').pop()?.includes('xls')) return thumbIcon.exel;
    return thumbIcon.csv;
  }
  return thumbIcon.banner;
};

type ValidatedFile = {
  file: File & { file_specs: string | null; id?: string };
  isValid: boolean;
};

type ValidateCsv = {
  file: File;
  isValid: boolean;
};

export const Uploader: React.FC<UploaderType> = ({
  children,
  onDropFile,
  type,
  fileSubmited,
  validate = true,
  multiple = true,
  showThumbs = true,
  areaWrapperClassname,
}) => {
  const [files, setFiles] = useState<FileType[]>([]);

  const validateBanner = async (file: File, validate: boolean = true): Promise<ValidatedFile> => {
    const reader = new FileReader();

    return new Promise(resolve => {
      reader.readAsDataURL(file);
      reader.onload = event => {
        const image = new Image();
        image.src = event?.target?.result as string;
        image.onload = () => {
          if (validate) {
            if (ImageDimensions.includes(`${image.width}x${image.height}`)) {
              return resolve({
                file: Object.assign(file, {
                  file_specs: `${image.width}x${image.height}`,
                  id: file.name,
                }),
                isValid: true,
              });
            }

            toast.error(`File does not meet the specified dimensions. (${file.name})`, {
              icon: <img src={warning_icon} alt="warn" />,
            });

            return resolve({
              file: Object.assign(file, {
                file_specs: `${image.width}x${image.height}`,
              }),
              isValid: false,
            });
          }
          return resolve({
            file: Object.assign(file, {
              file_specs: `${image.width}x${image.height}`,
              id: file.name,
            }),
            isValid: true,
          });
        };
        image.onerror = () => {
          toast.error(`Failed to read image "${file.name}"`);
          return resolve({
            file: Object.assign(file, {
              file_specs: `${image.width}x${image.height}`,
            }),
            isValid: false,
          });
        };
      };
      reader.onerror = () => {
        toast.error(`Failed to read file "${file.name}"`);
        return resolve({
          file: Object.assign(file, {
            file_specs: null,
          }),
          isValid: false,
        });
      };
    });
  };

  const validateVideo = async (file: File): Promise<ValidatedFile> => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.src = url;
    video.preload = 'metadata';

    return new Promise(resolve => {
      video.onloadedmetadata = () => {
        URL.revokeObjectURL(url);

        const { duration, videoWidth, videoHeight } = video;

        if (VideoDimensions.includes(`${videoWidth}x${videoHeight}`)) {
          if (duration === Infinity) {
            toast.error('Unsupported file.', {
              icon: <img src={warning_icon} alt="warn" />,
            });
            return resolve({
              file: Object.assign(file, {
                file_specs: formatVideoDuration(duration),
              }),
              isValid: false,
            });
          }
          if (Math.floor(duration) <= 60) {
            return resolve({
              file: Object.assign(file, {
                file_specs: formatVideoDuration(duration),
              }),
              isValid: true,
            });
          }

          toast.error('File should be no longer than 1 minute.', {
            icon: <img src={warning_icon} alt="warn" />,
          });
          return resolve({
            file: Object.assign(file, {
              file_specs: formatVideoDuration(duration),
            }),
            isValid: false,
          });
        }

        toast.error(`File does not meet the specified dimensions. (${file.name})`, {
          icon: <img src={warning_icon} alt="warn" />,
        });
        return resolve({
          file: Object.assign(file, {
            file_specs: formatVideoDuration(duration),
          }),
          isValid: false,
        });
      };

      video.onerror = () => {
        URL.revokeObjectURL(url);
        toast.error(`Failed to read file "${file.name}"`);
        return resolve({
          file: Object.assign(file, {
            file_specs: null,
          }),
          isValid: false,
        });
      };
    });
  };

  const validateCsv = async (file: File): Promise<ValidateCsv> => {
    return new Promise(resolve => {
      Papa.parse(file, {
        delimiter: '',
        chunkSize: 3,
        dynamicTyping: true,
        skipEmptyLines: true,
        header: true,
        complete: ({ data, errors, meta }) => {
          if (!data.length) {
            toast.error(`Can’t attach empty file. (${file.name})`, {
              icon: <img src={warning_icon} alt="warn" />,
            });
            return resolve({
              file,
              isValid: false,
            });
          }

          if (errors.length) {
            toast.error('Something went wrong!', {
              icon: <img src={warning_icon} alt="warn" />,
            });
            return resolve({
              file,
              isValid: false,
            });
          }
          return resolve({
            file,
            isValid: true,
          });
        },
      });
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const types = Object.values(mimeTypes[type])
        .reduce((previousValue, currentValue) => [...previousValue, ...currentValue], [])
        .map(type => type.split('.')[1])
        .concat(
          type === 'video' ? videoBrowserMimetypes : type === 'csv' ? csvBrowserMimetypes : []
        );

      if (multiple) {
        const filteredFiles = acceptedFiles.filter(
          acceptedFile => !files.find(file => file.name === acceptedFile.name)
        );

        if (
          acceptedFiles.length + files.length > maxFiles[type] &&
          (type === 'video' || type === 'banner')
        ) {
          if (filteredFiles.length + files.length > maxFiles[type]) {
            const toast_text = `${maxFiles[type]} ${type}s`;
            toast.error(`You can upload a maximum of ${toast_text} at a time.`, {
              icon: <img src={warning_icon} alt="warn" />,
            });
            acceptedFiles = filteredFiles.splice(0, maxFiles[type] - files.length);
          } else {
            toast.error(
              'Some of the files have already been selected, and the upload of those files has been skipped.',
              {
                icon: <img src={warning_icon} alt="warn" />,
              }
            );
            acceptedFiles = filteredFiles;
          }
        } else {
          if (filteredFiles.length !== acceptedFiles.length && files.length)
            toast.error(
              'Some of the files have already been selected, and the upload of those files has been skipped.',
              {
                icon: <img src={warning_icon} alt="warn" />,
              }
            );
          acceptedFiles = filteredFiles;
        }
      }

      acceptedFiles = acceptedFiles.filter(file => {
        if (!types.includes(file.type.split('/')[1]))
          toast.error(`File format is not supported. (${file.name})`, {
            icon: <img src={warning_icon} alt="warn" />,
          });

        return types.includes(file.type.split('/')[1]);
      });

      fileRejections.forEach(file => {
        const TypeError = file.errors.filter(eType => eType.code === 'file-invalid-type');
        if (TypeError.length === 0) {
          file.errors.forEach(err => {
            if (err.code === 'file-too-large') {
              if (type === 'banner')
                toast.error(`File is larger than 150KB. (${file.file.name})`, {
                  icon: <img src={warning_icon} alt="warn" />,
                });
              if (type === 'video')
                toast.error(`File is larger than 100MB. (${file.file.name})`, {
                  icon: <img src={warning_icon} alt="warn" />,
                });
            }
          });
        } else if (TypeError[0].code === 'file-invalid-type') {
          toast.error(`File format is not supported. (${file.file.name})`, {
            icon: <img src={warning_icon} alt="warn" />,
          });
        }
      });

      if (type === 'video') {
        const validatedFiles = await Promise.all(
          acceptedFiles.map(async file => {
            const { file: validatedFile, isValid } = await validateVideo(file);

            return {
              file: validatedFile,
              isValid,
            };
          })
        );

        const validFiles = validatedFiles.filter(({ isValid }) => isValid).map(({ file }) => file);

        setFiles(files => [...files, ...validFiles]);
        onDropFile({
          acceptedFiles: [...files, ...validFiles],
          rejectedFiles: fileRejections,
        });
      }

      if (type === 'banner') {
        const validatedFiles = await Promise.all(
          acceptedFiles.map(async file => {
            const { file: validatedFile, isValid } = await validateBanner(file, validate);

            return {
              file: validatedFile,
              isValid,
            };
          })
        );

        const validFiles = validatedFiles.filter(({ isValid }) => isValid).map(({ file }) => file);

        setFiles(files => [...files, ...validFiles]);
        onDropFile({
          acceptedFiles: [...files, ...validFiles],
          rejectedFiles: fileRejections,
        });
      }

      if (type === 'csv') {
        const validatedFiles = await Promise.all(
          acceptedFiles.map(async file => {
            const { file: validatedFile, isValid } = await validateCsv(file);

            return {
              file: validatedFile,
              isValid,
            };
          })
        );

        const validFiles = validatedFiles.filter(({ isValid }) => isValid).map(({ file }) => file);

        setFiles(files => [...files, ...validFiles]);
        onDropFile({
          acceptedFiles: [...files, ...validFiles],
          rejectedFiles: fileRejections,
        });
      }
    },
    [onDropFile, setFiles]
  );

  const onDelete = (file: File) => () => {
    const newFiles: any[] = files.filter(
      (accFile: any) => accFile.name !== file.name && accFile.lastModified !== file.lastModified
    );

    const dropFiles = {
      ...fileSubmited,
      acceptedFiles: newFiles,
    };

    setFiles(newFiles);
    onDropFile(dropFiles);
  };

  const thumbs = files.map((file: any, index: number) => {
    return (
      <div key={index} className="file-thumb">
        <div className="file-thumb-info-container">
          <div className="thumb-type-icon">{getFileThumbIcon(type, file.name)}</div>
          <div className="file-thumb-info">
            <span className="file-thumb-info-name">{file.name}</span>
            <span className="file-thumb-info-size">{formatBytes(file.size)}</span>
          </div>
        </div>
        <button aria-label="delete-file" className="delete-file-btn" onClick={onDelete(file)}>
          <FontAwesomeIcon icon={faTimes} color="#AAB2B5" style={{ fontSize: 14 }} />
        </button>
      </div>
    );
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: mimeTypes[type],
    maxSize: validate ? maxSize[type] : undefined,
    multiple,
  });

  return (
    <div>
      {showThumbs && <div className="thumbsContainer">{thumbs}</div>}

      <div
        className="upload_your_video"
        style={{
          marginBottom: '10px',
        }}
      >
        <div className={classNames('Uploader', areaWrapperClassname)} {...getRootProps()}>
          <input {...getInputProps()} />
          {children}
        </div>
      </div>
    </div>
  );
};

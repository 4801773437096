import React, { useState } from 'react';
import { Button, FlexBox, Text } from '@eltoro-ui/components';
import { AudienceStatusEnum, CampaignStep, RecommendationListingEnum } from 'enums';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import promoteSrc from 'assets/Images/promote_your_listing.svg';
import alertNeighbors from 'assets/Images/alert_neighbors.svg';
import { saveAudienceFromRecommendations } from 'Requests/Request_Methods/audienceMethods';
import { v4 as uuid } from 'uuid';
import { createCampaign } from 'Requests/Request_Methods/campaignMethods';
import SeeAssocietedCampaignModal from '../SeeAssocietedCampaignModal';
import ConfirmOwnershipModal from '../ConfirmOwnershipModal';
import './styles.scss';

interface Props {
  listingName: RecommendationListingEnum;
  isOwner?: boolean | null;
  address: string[];
  audienceStatus?: AudienceStatusEnum;
  creationDate?: number | null;
  isLounchCampaign: boolean;
  saveAndLaunchDisable: boolean;
  recommendationId: string;
  audienceId?: number | null;
  hasAssociatedCampaigns: boolean;
  listingId?: number;
  handleOwnershipUpdate: (newOwnershipStatus: boolean) => void;
}

const ListingTypeRecommendation = ({
  listingName,
  isOwner,
  address,
  audienceStatus,
  creationDate,
  isLounchCampaign,
  saveAndLaunchDisable,
  recommendationId,
  audienceId,
  hasAssociatedCampaigns,
  listingId,
  handleOwnershipUpdate,
}: Props) => {
  const [openIsYourOwnershipModal, setOpenIsYourOwnershipModal] = useState(false);
  const [openAssociatedModal, setAssociatedModal] = useState(false);
  const [audienceCreationLoading, setAudienceCreationLoading] = useState(false);
  const [launchCampaignLoading, setLaunchCampaignLoading] = useState(false);
  const fullAddress = address.filter(addressValue => addressValue !== '').join(', ');
  const parsedDate = moment(creationDate, [
    'YYYY-MM-DD HH:mm:ss.SSSSSSZ',
    'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
  ]);
  const listingCreationDate = parsedDate.format('LT');

  const saveAudienceFromRecommendationAPI = (body: Partial<any>) => {
    setAudienceCreationLoading(true);
    saveAudienceFromRecommendations({ recommendation_id: recommendationId })
      .then(res => {
        setAudienceCreationLoading(false);
        if (res.data) {
          window.location.pathname = `/create-campaign/${res.data.campaign_id}/setup`;
        }
      })
      .catch((err: any) => {
        setAudienceCreationLoading(false);
        console.error(err);
      })
      .finally(() => setAudienceCreationLoading(false));
  };

  const handleClick = () => {
    if (isLounchCampaign && audienceId) {
      const newCampaignName = uuid();
      setLaunchCampaignLoading(true);
      createCampaign({ name: newCampaignName, audience_id: audienceId })
        .then((res: any) => {
          if (res.data) {
            setLaunchCampaignLoading(false);
            if (res.data.step === CampaignStep.AUDIENCE) {
              window.location.pathname = `/create-campaign/${res.data?.id}/setup`;
            }
            if (res.data.step === CampaignStep.CREATIVE) {
              window.location.pathname = `/create-campaign/${res.data?.id}/creatives`;
            }
          }
        })
        .catch(err => {
          setLaunchCampaignLoading(false);
          console.error(err);
        })
        .finally(() => setLaunchCampaignLoading(false));
    } else {
      saveAudienceFromRecommendationAPI({ recommendation_id: recommendationId });
    }
  };

  const handleCloseIsOwnershipModal = () => {
    setOpenIsYourOwnershipModal(false);
  };

  const tooltipText =
    audienceStatus === AudienceStatusEnum.ACTIVE ? 'Audience Saved' : 'Audience Pending';

  const tooltipOverlay =
    audienceStatus === AudienceStatusEnum.ACTIVE
      ? 'For this type of alert, the audience is static, with the prospect count remaining unchanged over time.'
      : 'Your audience creation is in process. Please refresh after a few minutes.';

  return (
    <div className="listing_type_container">
      <FlexBox
        alignItems="flex-start"
        justifyContent="flex-start"
        gap="10px"
        UNSAFE_className="main_container"
      >
        <img
          src={listingName === RecommendationListingEnum.PROMOTION ? promoteSrc : alertNeighbors}
          alt={
            listingName === RecommendationListingEnum.PROMOTION
              ? 'Promote Your Listing'
              : 'Alert Neighbors'
          }
          style={{
            marginTop: '2.2rem',
          }}
        />
        <FlexBox
          alignItems="center"
          justifyContent="flex-start"
          flexDirection="column"
          gap="10px"
          UNSAFE_className="info_section"
        >
          <FlexBox
            alignItems="center"
            justifyContent="space-between"
            gap="10px"
            UNSAFE_className="listing_container"
          >
            <FlexBox
              alignItems="start"
              justifyContent="flex-start"
              flexDirection="column"
              UNSAFE_className="listing_desc"
              gap="10px"
            >
              {listingCreationDate && (
                <Text on="white" size="s" UNSAFE_style={{ color: '#9B9B9B' }}>
                  {listingCreationDate}
                </Text>
              )}
              <FlexBox alignItems="start" justifyContent="flex-start" gap="8px">
                <Text on="white" weight="bold">
                  {listingName === RecommendationListingEnum.PROMOTION
                    ? 'Promote Your Listing'
                    : listingName === RecommendationListingEnum.ALERT_NEIGHBORS
                    ? 'Alert Neighbors about New Listing'
                    : 'Alert Neighbors about Sold Listing'}
                </Text>
                <Text on="white" UNSAFE_style={{ color: '#D9D9D9' }}>
                  &#x2022;
                </Text>
                <Text on="white" weight="bold">
                  {fullAddress}
                </Text>
              </FlexBox>
              <Text on="white" UNSAFE_className="desc">
                {listingName === RecommendationListingEnum.PROMOTION
                  ? 'Congrats on your new listing! We have located intenders seen at comparable properties. Save the audience and launch your campaign to engage with potential buyers.'
                  : listingName === RecommendationListingEnum.ALERT_NEIGHBORS
                  ? 'Congrats on your new listing! We have identified targets that reside around that area. Save the audience and launch your campaign to engage with potential buyers.'
                  : 'Congrats on closing the deal! We have identified targets that reside around that area. Save the audience and launch your campaign to engage with potential sellers. '}
              </Text>
            </FlexBox>
            <FlexBox alignItems="center" flexDirection="column" justifyContent="center" gap="15px">
              <Button
                size="l"
                kind="primary"
                weight="bold"
                UNSAFE_className="save_or_launch_btn"
                onClick={handleClick}
                disabled={saveAndLaunchDisable}
                loading={isLounchCampaign ? launchCampaignLoading : audienceCreationLoading}
              >
                {isLounchCampaign ? 'Launch Campaign' : 'Save & Launch'}
              </Button>
              {audienceStatus &&
                audienceStatus !== AudienceStatusEnum.ERROR &&
                (listingName === RecommendationListingEnum.ALERT_NEIGHBORS ||
                  listingName === RecommendationListingEnum.ALERT_ABOUT_SOLD) && (
                  <Tooltip
                    placement="bottom"
                    trigger="hover"
                    showArrow
                    overlay={
                      <div
                        style={{
                          width: '255px',
                        }}
                      >
                        {tooltipOverlay}
                      </div>
                    }
                    getTooltipContainer={() => document.body}
                  >
                    <div>
                      <Text
                        on="white"
                        weight="normal"
                        UNSAFE_style={{
                          color:
                            audienceStatus === AudienceStatusEnum.ACTIVE ? '#52C41A' : '#888888',
                          fontSize: '14px',
                        }}
                      >
                        {tooltipText}
                        <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: '4px' }} />
                      </Text>
                    </div>
                  </Tooltip>
                )}
            </FlexBox>
          </FlexBox>
          <FlexBox
            alignItems="center"
            justifyContent={hasAssociatedCampaigns ? 'space-between' : 'flex-start'}
            gap="10px"
            UNSAFE_className="ownership_container"
          >
            <FlexBox alignItems="center" justifyContent="flex-start" gap="8px">
              {!isOwner ? (
                <>
                  <Text on="white" size="s" weight={600} UNSAFE_style={{ color: '#ABABAB' }}>
                    Marked as not your listing
                  </Text>
                  <Text on="white" UNSAFE_style={{ color: '#D9D9D9' }}>
                    &#x2022;
                  </Text>
                  <button
                    type="button"
                    className="edit_is_your_listing"
                    onClick={() => setOpenIsYourOwnershipModal(true)}
                  >
                    Edit
                  </button>
                </>
              ) : (
                <button
                  className="is_your_listing_btn"
                  type="button"
                  onClick={() => setOpenIsYourOwnershipModal(true)}
                >
                  Not your listing?
                </button>
              )}
            </FlexBox>
            {hasAssociatedCampaigns && (
              <button
                type="button"
                className="see_campaigns"
                onClick={() => setAssociatedModal(true)}
              >
                See Associated Campaigns
              </button>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {openAssociatedModal && (
        <SeeAssocietedCampaignModal
          recommendationId={recommendationId}
          handleClose={() => setAssociatedModal(false)}
          addressString={fullAddress}
        />
      )}
      {openIsYourOwnershipModal && typeof isOwner === 'boolean' && (
        <ConfirmOwnershipModal
          closeModal={handleCloseIsOwnershipModal}
          isOwner={isOwner}
          listingInfo={fullAddress}
          listingId={listingId}
          onOwnershipChange={handleOwnershipUpdate}
        />
      )}
    </div>
  );
};

export default ListingTypeRecommendation;

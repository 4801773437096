import { APIFeatureType } from 'types';

export const setUser = (payload: any) => {
  return {
    type: 'SET_USER',
    payload,
  };
};

export const setRecommendations = (payload: any) => {
  return {
    type: 'SET_RECOMMENDATIONS',
    payload,
  };
};

export const setUserMail = (payload: string) => {
  return {
    type: 'SET_USER_MAIL',
    payload,
  };
};

export const setSpinner = (payload: boolean) => {
  return {
    type: 'SET_SPINNER',
    payload,
  };
};

export const setChargebeePackage = (payload: any) => {
  return {
    type: 'SET_CHARGEBEE_PACKAGE',
    payload,
  };
};

export const setUserComplete = (payload: boolean) => {
  return {
    type: 'SET_USER_COMPLETE',
    payload,
  };
};

export const setCenterLens = (payload: boolean) => {
  return {
    type: 'SET_CENTER_LENS',
    payload,
  };
};

export const setSidebarLens = (payload: boolean) => {
  return {
    type: 'SET_LENS_SIDEBAR',
    payload,
  };
};

export const setSelectedListing = (payload: APIFeatureType[]) => {
  return {
    type: 'SET_SELECTED-LIST',
    payload,
  };
};

export const setOpenTagModal = (payload: boolean) => {
  return {
    type: 'SET_TAG_MODAL',
    payload,
  };
};

export const setFilteredBuzz = (payload: string[]) => {
  return {
    type: 'SET_FILTERED_BUZZ',
    payload,
  };
};
export const setSelectedMarker = (payload: any) => {
  return {
    type: 'SET_SELECTED_MARKER',
    payload,
  };
};

export const setRefreshList = (payload: any) => {
  return {
    type: 'SET_REFRESH_LIST',
    payload,
  };
};
export const setSelectedPage = (payload: number) => {
  return {
    type: 'SET_SELECTED_PAGE',
    payload,
  };
};

export const setTypeSearch = (payload: string) => {
  return {
    type: 'SET_TYPED_SEARCH',
    payload,
  };
};

export const setSelectedSort = (payload: { label?: string; value?: string } | undefined) => {
  return {
    type: 'SET_SELECTED_SORT',
    payload,
  };
};

export const setTargetAudience = (payload: string) => {
  return {
    type: 'SET_AUDIENCE_NAME',
    payload,
  };
};

export const setIsAudienceSaved = (payload: boolean) => {
  return {
    type: 'IS_AUDIENCE_SAVED',
    payload,
  };
};

export const setIsLaunchCampaign = (payload: boolean) => {
  return {
    type: 'SET_ISLAUNCHCAMPAIGN',
    payload,
  };
};
export const setCampaignId = (payload: number | null | { id?: string }) => {
  return {
    type: 'SET_CAMPAIGN_ID',
    payload,
  };
};

export const setLaunchProspect = (payload: boolean) => {
  return {
    type: 'SET_LAUNCH_CAMPAIGN',
    payload,
  };
};

export const setSelectedTableList = (payload: any) => {
  return {
    type: 'SET_SELECTED_TABLELIST',
    payload,
  };
};

export const setNotificationCall = (payload: any) => {
  return {
    type: 'SET_NOTIFICATION_CALLED',
    payload,
  };
};

export const setUnReadNotification = (payload: boolean) => {
  return {
    type: 'SET_UN_READ_NOTIFICATION',
    payload,
  };
};

export const setUnReadNotificationCount = (payload: number) => {
  return {
    type: 'SET_UN_READ_NOTIFICATION_COUNT',
    payload,
  };
};

export const setUserNotification = (payload: any) => {
  return {
    type: 'SET_USER_NOTIFICATION',
    payload,
  };
};
export const currentCampaignName = (payload: any) => {
  return {
    type: 'CURRENT_CAMPAIGN_NAME',
    payload,
  };
};
export const globalFilters = (payload: any) => {
  return {
    type: 'GALOBAL_FILTERS',
    payload,
  };
};
export const setGlobalFilters = (payload: any) => {
  return {
    type: 'SET_GALOBAL_FILTERS',
    payload,
  };
};

import React, { useEffect, useState } from 'react';
import { Modal, FlexBox, Text } from '@eltoro-ui/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssociatedCampaign } from 'types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Skeleton } from 'primereact/skeleton';
import { getAssociatedCampaigns } from 'Requests/Request_Methods/audienceMethods';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import CampaignCard from './components/CampaignCard';
import './styles.scss';

interface Props {
  handleClose: () => void;
  addressString: string;
  recommendationId: string;
}

const SeeAssocietedCampaignModal = ({ handleClose, addressString, recommendationId }: Props) => {
  const [associatedCampaigns, setAssociatedCampaigns] = useState<AssociatedCampaign[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    let isCanceled = false;

    async function getCampaigns(page: number) {
      if (!isCanceled) {
        setIsFetchingMore(true);
        const resp = await getAssociatedCampaigns(recommendationId, page);
        if (resp.data) {
          setAssociatedCampaigns(prevCampaigns => [...prevCampaigns, ...resp?.data?.campaigns]);
          setHasMore(resp.data.current_page < resp.data.total_pages);
          setIsLoading(false);
          setIsFetchingMore(false);
        }
      }
    }
    getCampaigns(page);

    return () => {
      isCanceled = true;
    };
  }, [recommendationId, page]);

  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasMore && !isFetchingMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <Modal closable offClick={() => {}} className="AssocietedCampaignModal">
      <FlexBox
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="8px"
        UNSAFE_className="associeted_modal_container"
      >
        <div className="cross">
          <FontAwesomeIcon icon={faTimes} onClick={handleClose} color="#6d6d6d" />
        </div>
        <FlexBox
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="8px"
          UNSAFE_className="heading"
        >
          <Text on="white" weight="bold" UNSAFE_className="title">
            Associated Campaigns
          </Text>
          <Text on="white" weight="normal" UNSAFE_className="address">
            {addressString}
          </Text>
        </FlexBox>
        {isLoading ? (
          <div className="skeleton_loader">
            {[1, 2, 3].map(sk => (
              <Skeleton key={sk} width="100%" height="118px" />
            ))}
          </div>
        ) : (
          <ul className="campaigns_list" onScroll={handleScroll}>
            {associatedCampaigns.map(campaign => (
              <CampaignCard
                key={campaign.id}
                step={campaign.step}
                campaignId={campaign.id}
                campaignName={campaign.name}
                creationDate={campaign.created_at}
                campaignStatus={campaign.status}
              />
            ))}
            {isFetchingMore && (
              <FlexBox alignSelf="center" UNSAFE_className="loading-spinner">
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  style={{
                    fontSize: 20,
                    color: '#FFAB03',
                    animation: 'spinner-border 0.75s linear infinite',
                  }}
                />
              </FlexBox>
            )}
          </ul>
        )}
      </FlexBox>
    </Modal>
  );
};

export default SeeAssocietedCampaignModal;

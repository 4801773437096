import React, { useRef, useState } from 'react';

import { toast } from 'react-hot-toast';
import { Panel } from 'primereact/panel';

import { Button, FlexBox, GridBox, Text } from '@eltoro-ui/components';
import { Uploader } from 'Components/Uploader';

import {
  UploadAreaLayout,
  UploadTypes,
} from 'Pages/CampaignCreation/components/CampaignCreatives/_components/UploadAreaLayout';

import { uploadContactsCSV } from 'Requests';

import type { FileUploadType } from 'types';
import { GuideEnum, UserDeviceEnum } from 'enums';

export const StepTwo = ({ onClose }: { onClose: (e?: boolean) => void }) => {
  const panelRef = useRef<Panel>(null);

  const [file, setfile] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = (files: FileUploadType) => {
    if (files.acceptedFiles) {
      setfile(files.acceptedFiles);
    }
  };

  const postCSV = () => {
    setLoading(true);
    const Form = new FormData();
    file.forEach(csv => {
      Form.append('files', csv);
    });
    Form.append('device', UserDeviceEnum.WEB);
    uploadContactsCSV(Form)
      .then((res: any) => {
        if (res.data.detail) {
          toast.error('Invalid csv');
          setLoading(false);
        } else {
          const guideBotContacts = sessionStorage.getItem('guideBotContacts');
          const botData = guideBotContacts ? JSON.parse(guideBotContacts) : null;
          if (botData?.type === GuideEnum.CONTACTS) sessionStorage.removeItem('guideBotContacts');
        }
        onClose(true);
      })
      .catch(() => {
        toast.error('Oops, there was an issue with importing your contacts.');
        setLoading(false);
      });
  };

  return (
    <FlexBox flexDirection="column" gap="8px">
      <GridBox justifyItems="flex-start" gap="0.5rem">
        <Text on="white" size="l" weight={600} UNSAFE_style={{ color: '#FFAB03' }}>
          STEP 2
        </Text>
        <Panel
          ref={panelRef}
          toggleable
          collapsed
          expandIcon={
            <Text on="white" weight="normal" UNSAFE_style={{ color: '#FFAB03' }}>
              Show file format details
            </Text>
          }
          collapseIcon={
            <Text on="white" weight="normal" UNSAFE_style={{ color: '#FFAB03' }}>
              Hide file format details
            </Text>
          }
          header={
            <FlexBox
              justifyContent="space-between"
              alignItems="center"
              UNSAFE_style={{ width: '100%' }}
            >
              <Text on="secondary-lighter" size="xxl" weight="bold">
                Upload Your List
              </Text>
            </FlexBox>
          }
        >
          <ol type="a" className="csv-format-details">
            <li>
              Column format : first name, last name, address, city, state, zip, phone number, email
            </li>
            <ol style={{ listStyle: 'none' }} className="csv-format-details-subs">
              <li>
                <i>
                  Example: <br />
                  John, Smith, 926 River Cove Ave FL, Orlando, 32825, +1 234 698 5874,
                  john@gmail.com John, Smith, 926 River Cove Ave FL, Orlando, 32825, +1 234 698
                  5874, john@gmail.com
                </i>
              </li>
            </ol>
            <li>
              Zip Code Column (Zip needs to be broken down into a 5-digit zip and must be preserve 0
              in zip codes beginning with the number 0).
            </li>
            <li>ENTER key, the insertion point moves to the next field/row</li>
            <li>Comma-separated string will convert to column next to the column</li>
            <li>Must be an Excel or CSV file ( save as CSV UTT-8 ( Comma delimited)(*.csv))</li>
          </ol>
        </Panel>
      </GridBox>
      <div>
        <Uploader type="csv" onDropFile={onSubmit}>
          <UploadAreaLayout uploadType={UploadTypes.csv} multiple />
        </Uploader>
      </div>
      <div className="UploadBannerModal__footer">
        <Button kind="default" size="l" weight="bold" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          kind="primary"
          size="l"
          weight="bold"
          onClick={postCSV}
          disabled={!file.length || loading}
        >
          Upload
        </Button>
      </div>
    </FlexBox>
  );
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, FlexBox, Modal, Text } from '@eltoro-ui/components';
import './styles.scss';
import ownership_closed_block from 'assets/Images/ownership_closed_block.svg';
import ownership_opened_block from 'assets/Images/ownership_opened_block.svg';
import { addToMyListing, removeMyListing } from 'Requests/Request_Methods/userMethods';
import toast from 'react-hot-toast';

type Props = {
  closeModal: () => void;
  isOwner: boolean;
  listingInfo: string;
  listingId?: number;
  onOwnershipChange: (newOwnershipStatus: boolean) => void;
};
const ConfirmOwnershipModal = ({
  closeModal,
  isOwner,
  listingInfo,
  listingId,
  onOwnershipChange,
}: Props) => {
  const handleIsMyListing = () => {
    if (listingId) {
      if (isOwner) {
        removeMyListing(listingId)
          .then(res => {
            onOwnershipChange(false);
            closeModal();
            toast.success('Listing successfully marked as not yours.');
          })
          .catch(err => {
            toast.error('Failed to mark the listing as not yours.');
          });
      } else {
        addToMyListing(listingId)
          .then(() => {
            onOwnershipChange(true);
            closeModal();
            toast.success('Listing successfully marked as yours.');
          })
          .catch(err => {
            toast.error('Failed to mark the listing as yours.');
          });
      }
    }
  };

  return (
    <Modal offClick={closeModal}>
      <div className="OnboardingModal__welcome ownership_position">
        <div className="OnboardingModal__welcomeHeader ownership_modal">
          <div className="SaveAudienceModal__cancel">
            <FontAwesomeIcon icon={faTimes} onClick={closeModal} color="#6d6d6d" />
          </div>
          <FlexBox alignItems="center" flexDirection="column" gap="20px">
            <img
              src={isOwner ? ownership_closed_block : ownership_opened_block}
              alt="your listing"
            />
            <Text on="white" weight="bold">
              {isOwner ? 'Not your listing?' : 'Your listing?'}
            </Text>
            <p className="is_your_listing_or_not">
              If you confirm that <span className="listing_address">{listingInfo}</span> is{' '}
              {isOwner ? 'not' : ''} your listing we {isOwner ? 'won’t' : 'will'} show{' '}
              {isOwner ? 'any' : 'more'} recommendations related to it.
            </p>
          </FlexBox>
          <FlexBox justifyContent="center" gap="32px" UNSAFE_className="is_your_listing_btns">
            <Button
              size="l"
              kind="default"
              weight="bold"
              onClick={closeModal}
              UNSAFE_className="cancel_ownership_btn"
            >
              Cancel
            </Button>
            <Button size="l" kind="primary" weight="bold" onClick={handleIsMyListing}>
              {isOwner ? 'Mark as not mine' : 'Mark as mine'}
            </Button>
          </FlexBox>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmOwnershipModal;

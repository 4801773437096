import React from 'react';
import { Button, FlexBox, Text } from '@eltoro-ui/components';
import Tooltip from 'rc-tooltip';
import { CampaignStatusEnum, CampaignStep, campaignStepName } from 'enums';
import moment from 'moment/moment';
import classNames from 'classnames';
import './styles.scss';

interface Props {
  step: CampaignStep;
  campaignId: number;
  creationDate: string;
  campaignName: string;
  campaignStatus: string;
}

const CampaignCard = ({ creationDate, campaignName, campaignStatus, campaignId, step }: Props) => {
  let statusColor;
  let statusName;
  let btnText;
  let handleBtnAction;

  switch (campaignStatus) {
    case CampaignStatusEnum.IN_REVIEW:
      statusName = 'Submitted';
      statusColor = '#52C41A';
      btnText = 'See Details';
      handleBtnAction = () => window.location.assign(`/campaigns/${campaignId}`);
      break;
    case CampaignStatusEnum.ERROR:
      statusName = 'Error';

      if (step === CampaignStep.CREATIVE) {
        statusName = 'Creative error';
      }

      if (step === CampaignStep.AUDIENCE) {
        statusName = 'Audience error';
      }

      if (step === CampaignStep.DONE) {
        statusName = 'Creation failed';
      }

      statusColor = '#FF2D38';
      btnText = 'Continue to Edit';
      handleBtnAction = () =>
        window.location.assign(`/create-campaign/${campaignId}/${campaignStepName[step]}`);
      break;
    case CampaignStatusEnum.PAYMENT_FAILED:
      if (step === CampaignStep.CHECKOUT) {
        statusName = 'Payment error';
      }
      statusColor = '#FF2D38';
      btnText = 'Continue to Edit';
      handleBtnAction = () =>
        window.location.assign(`/create-campaign/${campaignId}/${campaignStepName[step]}`);
      break;
    default:
      statusName = 'Draft';
      statusColor = '#888888';
      btnText = 'Continue to Launch';
      handleBtnAction = () =>
        window.location.assign(`/create-campaign/${campaignId}/${campaignStepName[step]}`);
  }

  const isDisabled = campaignStatus === CampaignStatusEnum.ERROR && step === CampaignStep.DONE;

  return (
    <li className="campaigns_card_container">
      <FlexBox alignItems="center" justifyContent="space-between" gap="20px">
        <FlexBox
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          gap="10px"
        >
          {creationDate && (
            <Text on="grey-200" weight="normal" UNSAFE_className="creation_date">
              Created: {moment(`${creationDate}Z`).format('MMM DD, hh:mmA')}
            </Text>
          )}
          <FlexBox alignItems="flex-start" justifyContent="flex-start" gap="5px">
            <Tooltip
              placement="topRight"
              trigger="hover"
              overlayClassName={classNames('audience-tooltip')}
              showArrow
              align={{
                offset: [5, -3],
                targetOffset: [-6, 0],
              }}
              overlay={campaignName}
              getTooltipContainer={() => document.body}
            >
              <span className="campaign_name">{campaignName}</span>
            </Tooltip>
            <span style={{ color: '#D9D9D9', fontSize: '15px' }}>&#9679;</span>
            <Text
              on="white"
              UNSAFE_className="campaign_status"
              UNSAFE_style={{ color: statusColor }}
            >
              {statusName}
            </Text>
          </FlexBox>
        </FlexBox>
        <Button
          disabled={isDisabled}
          size="m"
          kind="default"
          weight="bold"
          onClick={handleBtnAction}
          UNSAFE_className="campaign_action_btn"
        >
          {btnText}
        </Button>
      </FlexBox>
    </li>
  );
};

export default CampaignCard;

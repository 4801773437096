import React, { useEffect, useRef, useState } from 'react';
import type { Dispatch, ReactElement, SetStateAction } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch } from 'react-redux';

import toast from 'react-hot-toast';

import L, { LatLngTuple } from 'leaflet';

import { Button } from '@eltoro-ui/components';
import { CustomMapPopup } from 'Components';

import type { APIFeatureType, MapLocationType, TLocation } from 'types';
import type { GeoJsonObject } from 'geojson';
import type { TListingData } from 'Pages/ProspectActivity/lenses';

import { getBounds, getLocationSearchResults, getLocationSearchResultsOSMId } from 'Helpers';

import { setSpinner } from 'Redux/actions';

import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import 'leaflet-providers';
import 'leaflet/dist/leaflet.css';

import './Map.scss';

const center: L.LatLngExpression = [37.09024, -95.712891];

type MapProps = {
  tileProvider?: string;
  features: any;
  locations: MapLocationType[];
  polygon?: TLocation | null;
  polygons?: TLocation[];
  updatedList?: boolean;
  style?: any;
  setupdatedList?: (val: boolean) => void;
  refreshState?: boolean;
  setrefreshState?: (val: boolean) => void;
  locationsArea?: any[];
  buzzAvg?: any[];
  marker: (f: APIFeatureType) => ReactElement;
  targetedMarker?: (f: APIFeatureType) => ReactElement;
  popup: (f: APIFeatureType) => ReactElement;
  tooltip: (f: any) => ReactElement;
  type?: string;
  circle?: any;
  callPolygonFilters?: boolean;
  firstClicked?: boolean;
  setcallPolygonFilters?: (val: boolean) => void;
  searchClicked?: boolean;
  listingData?: any;
  setListingData?: Dispatch<SetStateAction<TListingData>>;
  setListings?: Dispatch<SetStateAction<APIFeatureType[]>>;
  setRadiusListing?: (val: number[]) => void;
  zoomLevel?: number | undefined;
};

export const Map: React.FC<MapProps> = ({
  tileProvider,
  features,
  locations,
  polygon,
  polygons,
  marker,
  popup,
  tooltip,
  style,
  locationsArea,
  targetedMarker,
  setrefreshState,
  refreshState,
  buzzAvg,
  updatedList,
  setupdatedList,
  firstClicked,
  type,
  circle,
  callPolygonFilters,
  setcallPolygonFilters,
  searchClicked,
  listingData,
  setListingData,
  setRadiusListing,
  setListings,
  zoomLevel,
}) => {
  const [bounds, setBounds] = useState(
    L.latLngBounds([42.2974279, -85.628292], [-8.852507, -45.351563])
  );

  // init map

  const dispatch = useDispatch();
  const mapRef = useRef<any>();
  useEffect(() => {
    const provider = tileProvider || 'CartoDB.VoyagerLabelsUnder';
    mapRef.current = L.map('leaflet-map', {
      fullscreenControl: true,
      closePopupOnClick: false,
      // maxZoom: 19,
      // minZoom: 2,
    }).setView(center, zoomLevel || 4);
    // .setZoom(1)

    // L.tileLayer.provider(provider, { noWrap: true }).addTo(mapRef.current)
    L.tileLayer.provider(provider).addTo(mapRef.current);
    // mapRef.current.addControl(new L.Control.Fullscreen())

    // When Dragging maps, close all open popups
    if (type !== 'Virtual Just Listed/Just Sold') {
      mapRef.current.on('dragstart', () => mapRef.current?.closePopup());
    }
  }, []);

  // init layer
  const layerRef = useRef<any>();
  useEffect(() => {
    if (mapRef.current) {
      layerRef.current = L.layerGroup().addTo(mapRef.current);
    }
  }, []);

  // if there are locations, set bounds
  useEffect(() => {
    if (locations.length > 0) {
      const locationsBounds = {
        lat: locations.reduce((acc: number[], location) => {
          const north = parseFloat(location.bounds[0][0] as string);
          const south = parseFloat(location.bounds[1][0] as string);
          return [...acc, north, south];
        }, []),
        long: locations.reduce((acc: number[], location) => {
          const east = parseFloat(location.bounds[0][1] as string);
          const west = parseFloat(location.bounds[1][1] as string);
          return [...acc, east, west];
        }, []),
      };

      setBounds(getBounds(locationsBounds.lat, locationsBounds.long));
    }
  }, [locations]);

  useEffect(() => {
    if (layerRef.current && (type === 'Likely Sellers' || type === 'Digital Farming')) {
      if (
        polygon &&
        (polygon.originalFeature.geometry.type === 'MultiPolygon' ||
          polygon.originalFeature.geometry.type === 'Polygon')
      ) {
        const polygonJSON = L.geoJSON(polygon.geometry as GeoJsonObject, {
          style: { color: '#F6B433' },
        }).addTo(layerRef.current);
        setBounds(polygonJSON.getBounds());
        if (mapRef.current) {
          mapRef.current.fitBounds(polygonJSON.getBounds()).setZoom(zoomLevel || 12);
        }
      } else {
        layerRef.current?.clearLayers();
      }
    }
  }, [polygon, layerRef.current, type]);

  useEffect(() => {
    if (layerRef.current) {
      if (polygons && type === 'Grow Your Sphere') {
        layerRef.current?.clearLayers();
        polygons.forEach(polygon => {
          if (
            polygon.originalFeature.geometry.type === 'MultiPolygon' ||
            polygon.originalFeature.geometry.type === 'Polygon'
          ) {
            const polygonJSON = L.geoJSON(polygon.geometry as GeoJsonObject, {
              style: { color: '#F6B432', fillColor: '#FBEAD0' },
              onEachFeature: (feature, layer) => {
                layer.bindTooltip(polygon.originalFeature.properties.postalcode as string, {
                  direction: 'top',
                });
                layer.on('mouseover', () => {
                  // @ts-ignore
                  layer.setStyle({ color: '#FFAC09', fillColor: '#FFE4BB' });
                  layer.openTooltip();
                });
                layer.on('mouseout', () => {
                  // @ts-ignore
                  layer.setStyle({ color: '#F6B432', fillColor: '#FBEAD0' });
                  layer.closeTooltip();
                });
              },
            }).addTo(layerRef.current);

            setBounds(polygonJSON.getBounds());
            if (mapRef.current) {
              mapRef.current.fitBounds(polygonJSON.getBounds()).setZoom(zoomLevel || 12);
            }
          } else {
            layerRef.current?.clearLayers();
          }
        });
      }
    }
  }, [polygons, layerRef.current, type]);

  const metersCalculation = (radius: { label: string } | undefined) => {
    const meters = [1000, 750, 500];
    if (radius?.label === '2,000 meters') {
      return meters[0];
    }
    if (radius?.label === '1,500 meters') {
      return meters[1];
    }
    return meters[2];
  };

  mapRef.current?.on('fullscreenchange', () => {
    if (mapRef.current.isFullscreen()) {
      mapRef.current.panTo(bounds, zoomLevel || 4);
      fitMapToBounds();
    } else {
      const provider = tileProvider || 'CartoDB.VoyagerLabelsUnder';
      const updateCenter: L.LatLngExpression = [41.318043148599706, -87.02493358848308];
      mapRef.current = L.map('leaflet-map', {
        minZoom: 2,
        maxZoom: 6,
      }).setView(updateCenter, zoomLevel || 4);
      L.tileLayer.provider(provider).addTo(mapRef.current);
      mapRef.current.panTo(updateCenter, zoomLevel || 4);
    }
  });

  useEffect(() => {
    if (callPolygonFilters) {
      if (!firstClicked) {
        layerRef.current?.clearLayers();
      }
      polygonFilters();
    }
  }, [callPolygonFilters]);
  const polygonFilters = () => {
    if (
      (type === 'Sell Your Listing Faster' || type === 'Expand Your Marketing') &&
      locationsArea &&
      locationsArea.length > 0
    ) {
      const centerMap: any = [];
      for (let i = 0; i < locationsArea.length; i += 1) {
        let buzzData: any;
        if (buzzAvg) {
          buzzData = {
            street: locationsArea[i].display_value,
            activity_score: buzzAvg[i],
            mls_id: `${'locationsArea'[i]}`,
          };
        }

        dispatch(setSpinner(true));
        getLocationSearchResults(locationsArea[i].value)
          .then(resp => {
            getLocationSearchResultsOSMId(resp.results[0].osm_id)
              .then(res => {
                if (res.length > 0) {
                  if (res[0].geojson.type === 'Polygon') {
                    const singlePolLatLngs = L.GeoJSON.coordsToLatLngs(
                      res[0].geojson.coordinates,
                      1
                    );
                    centerMap.push(singlePolLatLngs);
                    const singlePol = L.polygon(singlePolLatLngs, {
                      color: 'blue',
                      fillColor: 'skyblue',
                    });

                    singlePol
                      .bindPopup(
                        renderToString(<CustomMapPopup feature={buzzData} prospect={false} />)
                      )
                      .openPopup()
                      .addTo(layerRef.current);
                  } else {
                    // base64Creatives.push(
                    //   ...L.GeoJSON.coordsToLatLngs(res[0].geojson.coordinates, 2),
                    // )
                    const multiPolLatLngs = L.GeoJSON.coordsToLatLngs(
                      res[0].geojson.coordinates,
                      2
                    );
                    centerMap.push(multiPolLatLngs);

                    const multiPol = L.polygon(multiPolLatLngs, {
                      color: 'blue',
                      fillColor: 'skyblue',
                    });

                    multiPol
                      .bindPopup(
                        renderToString(<CustomMapPopup feature={buzzData} prospect={false} />)
                      )
                      .openPopup()
                      .addTo(layerRef.current);
                    // if (type === 'my listing') {
                    // pol.on('mouseover', () => featureToolTip(pol).openTooltip())
                    // }
                  }
                  if (i === locationsArea.length - 1) {
                    setBounds(centerMap);
                    dispatch(setSpinner(false));
                    // layerRef.current.panTo()
                    // const group = L.featureGroup(centerMap)
                    // setBounds(L.latLngBounds([position, position2])
                  }
                } else {
                  if (i === locationsArea.length - 1) {
                    dispatch(setSpinner(false));
                    // layerRef.current.panTo()
                    // const group = L.featureGroup(centerMap)
                    // setBounds(L.latLngBounds([position, position2])
                  }
                  toast.error(`No polygon found for ${locationsArea[i].display_value}`);
                }
              })
              .catch(err => {
                toast.error(err.detail);
              })
              .finally(() => {
                if (i === locationsArea.length - 1 && setcallPolygonFilters) {
                  setcallPolygonFilters(false);
                  // dispatch(setSpinner(false))
                }
              });
          })
          .catch(err => {});
      }
      // }
    }
  };

  // add features to layer
  useEffect(() => {
    if (type !== 'Likely Sellers' && type !== 'Grow Your Sphere' && type !== 'Digital Farming') {
      features = features.filter(
        (listing: { latitude: number; longitude: number }) => listing.latitude && listing.longitude
      );
      const mcg = L.markerClusterGroup({ chunkedLoading: true });

      mcg.clearLayers();
      layerRef.current?.clearLayers();

      if (layerRef.current) {
        let i = 0;
        const newList: any = [];
        const inRadiusObj: any = {};
        const countInObjList: any = [];
        let ListingUpdate: any[];
        if (listingData) {
          ListingUpdate = [...listingData.listings];
        }

        if (features.length >= 1 && type !== 'Likely Sellers' && type !== 'Digital Farming')
          features?.forEach((feature: any) => {
            i += 1;
            const featureToolTip = (currentFeatureMarker: L.Marker) => {
              return currentFeatureMarker.bindTooltip(renderToString(tooltip(feature)), {
                direction: 'top',
                offset: [4, -27],
              });
            };

            const featureMarker = L.marker([feature.latitude, feature.longitude], {
              icon: L.divIcon({
                html: renderToString(marker(feature)),
              }),
              title: feature.street ? feature.street : undefined,
            });

            if (type === 'Sell Your Listing Faster') {
              featureMarker.bindPopup(renderToString(popup(feature)), {
                offset: [-4, 0],
              });
              featureMarker.on('mouseover', () => {
                featureMarker.openPopup();
              });
              featureMarker.on('mouseout', () => {
                featureMarker.closePopup();
              });
            }

            if (type === 'my listing') {
              featureMarker
                .bindPopup(renderToString(<CustomMapPopup feature={feature} />))
                .openPopup()
                .addTo(layerRef.current);
            } else mcg.addLayer(featureMarker);

            if (type === 'my listing') {
              featureMarker.on('mouseover', () => featureToolTip(featureMarker).openTooltip());
            }

            if (type === 'Virtual Just Listed/Just Sold' && feature?.selected) {
              const theRadius = metersCalculation(feature?.radius);

              const selPts: any = [];

              const circleNew = L.circle([feature.latitude, feature.longitude], {
                radius: theRadius,
                color: '#44C2E64D',
                fillColor: '#44C2E64D',
                fillOpacity: 0.5,
              }).addTo(layerRef.current);
              const xy: LatLngTuple = [feature.latitude, feature.longitude];

              let latlng_b;

              // checking if property exists in the array and add into the object like {'listingid': 'unique_observations'}
              for (let k = 0; k < features.length; k += 1) {
                latlng_b = new L.LatLng(features[k]?.latitude, features[k]?.longitude);
                if (
                  latlng_b.distanceTo(xy) < theRadius &&
                  feature.listingid !== features[k].listingid
                ) {
                  if (!inRadiusObj[feature.listingid]) {
                    inRadiusObj[feature.listingid] = features[k].unique_visitor_count;
                  } else {
                    inRadiusObj[feature.listingid] += features[k].unique_visitor_count;
                  }
                  countInObjList.push(features[k].listingid);
                  selPts.push({ i: features[k].unique_visitor_count });
                }
                if (k === features.length - 1 && setRadiusListing) {
                  setRadiusListing(countInObjList);
                }
              }

              for (let l = 0; l < features.length; l += 1) {
                if (inRadiusObj[ListingUpdate[l].listingid]) {
                  ListingUpdate[l].unique_observations_total =
                    inRadiusObj[ListingUpdate[l].listingid] + ListingUpdate[l].unique_visitor_count;
                } else {
                  ListingUpdate[l].unique_observations_total = 0;
                }
              }

              newList.push([circleNew.getBounds()]);
              if (i === features.length - 1) {
                setBounds(newList);
              }
            }
          });
        layerRef?.current?.addLayer(mcg);

        if (type === 'Virtual Just Listed/Just Sold' && !searchClicked) {
          const featureBounds = {
            lats: features.map((feature: any) => feature.latitude),
            longs: features.map((feature: any) => feature.longitude),
          };
          setBounds(getBounds(featureBounds.lats, featureBounds.longs));
        }

        if (features.length > 0 && type !== 'Virtual Just Listed/Just Sold') {
          const featureBounds = {
            lats: features.map((feature: any) => feature.latitude),
            longs: features.map((feature: any) => feature.longitude),
          };
          setBounds(getBounds(featureBounds.lats, featureBounds.longs));
        }
      }
    }
  }, [features]);

  // Map markers for selected listings

  // fit map to bounds when bounds are updated
  const fitMapToBounds = () => {
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds).setZoom(zoomLevel || 4);
    }
  };

  return (
    <div style={style} className="Map" id="leaflet-map">
      {features?.length > 0 && (
        <div className="Map__fit-to-bounds-button">
          <Button onClick={fitMapToBounds} rounded>
            Zoom to results
          </Button>
        </div>
      )}
    </div>
  );
};

import L from 'leaflet';
import { APIFeatureType } from 'types';
import { buzzFilters } from 'Pages/ProspectActivity/configs';
import { useSelector } from 'react-redux';
import pako from 'pako';
import { openStreepMapResolver } from 'Components';
import target from '../../assets/Images/target.png';
import prospects from '../../assets/Images/Group286.png';

export const getBounds = (lats: number[], longs: number[]) => {
  const upperCoords = L.latLng(Math.max(...lats), Math.max(...longs));
  const lowerCoords = L.latLng(Math.min(...lats), Math.min(...longs));
  return L.latLngBounds(upperCoords, lowerCoords);
};

const audienceSourceIcon = [
  {
    label: 'audience',
    icon: target,
  },
  {
    label: 'prospects',
    icon: prospects,
  },
];
export const getIconBuzzColor = (feature: APIFeatureType) => {
  let _color = '';
  buzzFilters.forEach(filter => {
    if (filter.test(feature.buzz_score)) {
      _color = filter.color;
    }
  });
  return _color;
};

// export const getCampignAudienceIcon = (source: any) => {
//   let icon = ''
//   audienceSourceIcon.forEach((filter) => {
//     if (filter.test(feature.activity_score)) {
//       _color = filter.label
//     })
// }
export const getBuzz = (feature: APIFeatureType) => {
  let _color = '';
  buzzFilters.forEach(filter => {
    if (filter.test(feature.buzz_score)) {
      _color = filter.label;
    }
  });
  return _color;
};

export const getPolygonBorderColor = (avg: any) => {
  let color = '';
  if (avg >= 0.75) {
    color = 'var(--color-data-warm-800)';
  } else if (avg < 0.75 && avg >= 0.5) {
    color = 'var(--color-data-warm-600)';
  } else if (avg < 0.5 && avg >= 0.25) {
    color = 'var(--color-data-warm-300)';
  } else {
    color = 'var(--color-data-warm-200)';
  }
  return color;
};

export const getLocationByLatLong = async (longitude: number, latitude: number) => {
  const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;
  return fetch(encodeURI(url))
    .then(res => res.json())
    .then(json => openStreepMapResolver([json])[0]);
};

export const getLocationSearchResults = async (query: string) => {
  const url = `https://search.osmnames.org/us/q/${query}.js?key=dgb7TgC5zR0YpsAqbEgb&place=city`;
  return fetch(encodeURI(url)).then(res => res.json());
};

export const getLocationSearchResultsOSMId = async (query: string) => {
  const url = `https://nominatim.openstreetmap.org/lookup?osm_ids=R${query}&format=json&polygon_geojson=1`;
  return fetch(encodeURI(url)).then(res => res.json());
};

// Input base64 encrypted data, Return decrypted data
export const getDecryptedData = (encrypted: any) => {
  const strData: any = atob(encrypted);
  const charData = strData.split('').map((x: any) => {
    return x.charCodeAt(0);
  });
  const binData: any = new Uint8Array(charData);
  const data: any = pako.inflate(binData);

  return JSON.parse(new TextDecoder('utf-8').decode(data));
  // return strData
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const recommendationsReducer: undefined | any = (
  state = { userRecommendations: null },
  action: any
) => {
  switch (action.type) {
    case 'SET_RECOMMENDATIONS':
      return { userRecommendations: action.payload };

    default:
      return { ...state };
  }
};

import {
  ResponseType,
  TContacts,
  TDeleteContacts,
  TUploadContacts,
  TUpdateContactInfo,
  TSaveAudience,
  TContactsZipCodesResponse,
} from 'types';
import { UserDeviceEnum } from 'enums';
import requests from '../http';

export type ContactsAudienceBody = {
  name?: string;
  tags?: string[];
  seen_in_market?: boolean;
  contact_type?: 'seller' | 'buyer' | null;
  recommendation_id?: string | null;
};

export type ContactsGrowYourSphereAudienceBody = {
  name: string;
  source: string;
  zip: string[];
  tags?: string[];
};

export const getContacts = async (
  page?: number | null,
  size?: number | null,
  isLens: boolean = false,
  sort_key: string | null = null,
  order: string | null = null
): Promise<ResponseType<TContacts>> => {
  const req = `/contacts?page=${page}&size=${size}${
    sort_key ? `&sort_key=${sort_key}&order=${order}` : ''
  }${isLens ? `&is_lens=${isLens}` : ''}`;
  return requests.get(req);
};

export const getContactDetailById = async (
  id: number | string
): Promise<ResponseType<TUpdateContactInfo>> => {
  const req = `/contacts/${id}`;
  return requests.get(req);
};

export const deleteContacts = async (body: Object): Promise<ResponseType<TDeleteContacts>> => {
  return requests.post(`/contacts/delete-contacts`, body);
};

export const uploadContactsCSV = async (
  body: Object | any
): Promise<ResponseType<TUploadContacts>> => {
  return requests.post(`/contacts/upload`, body, true);
};
export const uploadContactsSync = async (
  body: Object | any
): Promise<ResponseType<TUploadContacts>> => {
  return requests.post(`/contacts/sync`, {
    contacts: body,
    device: UserDeviceEnum.WEB,
  });
};

export const saveContacts = async (body: Object | any): Promise<ResponseType<TUploadContacts>> => {
  return requests.post(`/contacts/save`, body);
};

export const editContact = async (body: Object, id: number): Promise<ResponseType<any>> =>
  requests.put(`/contacts/${id}`, body);

export const saveContactsAudience = async (
  body: ContactsAudienceBody
): Promise<ResponseType<TSaveAudience>> => requests.post('/contacts/save_audience', body);

export const saveContactsGrowYourSphereAudience = async (
  body: ContactsGrowYourSphereAudienceBody
): Promise<ResponseType<TSaveAudience>> => requests.post('/contacts/save/grow_your_sphere', body);

export const getContactsZipCodes = async (): Promise<ResponseType<TContactsZipCodesResponse>> =>
  requests.get('/contacts/grow_your_sphere/zip_codes');

import React, { useRef, useState, useEffect } from 'react';
import type { ChangeEventHandler } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faPlus, faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useDebouncedCallback } from '@eltoro-ui/hooks';
import { Modal, FlexBox, Text, Button } from '@eltoro-ui/components';
import toast from 'react-hot-toast';

import type { TSuggestedListing, APIFeatureType } from 'types';

import { searchListingById, addToMyListing } from 'Requests';
import ownership_opened_block from 'assets/Images/ownership_opened_block.svg';

import './ListingSearch.scss';

interface ListingSearchProps {
  onAdd: (listing: APIFeatureType) => void;
}

interface ListingSearchModalProps extends ListingSearchProps {
  onClose: () => void;
}

interface AddToMyListingModalProps {
  listing: TSuggestedListing;
  closeModal: () => void;
  handleAddToMyListing: (listing: TSuggestedListing) => void;
}

export const ListingSearch = ({ onAdd }: ListingSearchProps) => {
  const [isSearchActive, setIsSearchActive] = useState(false);

  return (
    <div className="ListingSearch">
      <button
        type="button"
        className="ListingSearch__button"
        onClick={() => setIsSearchActive(active => !active)}
      >
        <FontAwesomeIcon icon={faSearch} />
        <span className="content">Search by listing ID</span>
      </button>

      {isSearchActive && (
        <ListingSearchModal onAdd={onAdd} onClose={() => setIsSearchActive(false)} />
      )}
    </div>
  );
};

export const AddToMyListingModal = ({
  listing,
  closeModal,
  handleAddToMyListing,
}: AddToMyListingModalProps) => {
  const address = [
    listing.street ?? '',
    listing.city ?? '',
    listing.state ?? '',
    listing.zip ?? '',
  ];
  const fullAddress = address.filter(addressValue => addressValue !== '').join(', ');

  return (
    <Modal offClick={closeModal}>
      <div className="OnboardingModal__welcome ownership_position">
        <div className="OnboardingModal__welcomeHeader ownership_modal">
          <div className="SaveAudienceModal__cancel">
            <FontAwesomeIcon icon={faTimes} onClick={closeModal} color="#6d6d6d" />
          </div>
          <FlexBox alignItems="center" flexDirection="column" gap="20px">
            <img src={ownership_opened_block} alt="your listing" />
            <Text on="white" weight="bold">
              Add to your listings?
            </Text>
            <p className="is_your_listing_or_not">
              The listing at <span className="listing_address">{fullAddress}</span> is currently
              marked as not yours. Adding it to your listings will mark it as yours.
            </p>
          </FlexBox>
          <FlexBox justifyContent="center" gap="32px" UNSAFE_className="is_your_listing_btns">
            <Button
              size="l"
              kind="default"
              weight="bold"
              onClick={closeModal}
              UNSAFE_className="cancel_ownership_btn"
            >
              Cancel
            </Button>
            <Button
              size="l"
              kind="primary"
              weight="bold"
              onClick={() => handleAddToMyListing(listing)}
            >
              Add Listing
            </Button>
          </FlexBox>
        </div>
      </div>
    </Modal>
  );
};

export const ListingSearchModal = ({ onAdd, onClose }: ListingSearchModalProps) => {
  const [searchedText, setSearchedText] = useState('');

  const [suggestions, setSuggestions] = useState<TSuggestedListing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addListingLoading, setAddListingLoading] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedListing, setSelectedListing] = useState<TSuggestedListing | null>(null);

  useEffect(() => inputRef.current?.focus(), []);

  const handleAddToMyListing = async (listing: TSuggestedListing) => {
    try {
      setAddListingLoading(listing.listingid);
      const res = await addToMyListing(listing.listingid);

      if (res.data) {
        onAdd(res.data);
        setSuggestions(suggestions =>
          suggestions.map(suggestion =>
            res.data?.listingid && suggestion.listingid === res.data.listingid
              ? { ...suggestion, added: true, is_owner: true }
              : suggestion
          )
        );
        toast.success('Listing successfully marked as yours');
      }
      setSelectedListing(null);
      setAddListingLoading(0);
    } catch (e) {
      setAddListingLoading(0);
      setSelectedListing(null);
      toast.error('Failed to mark the listing as yours.');
    }
  };

  const onAddIntoMyListings = (listing: TSuggestedListing) => async () => {
    if (listing.added && !listing.is_owner) {
      setSelectedListing(listing);
    } else {
      handleAddToMyListing(listing);
    }
  };

  const onChangeSearch: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) =>
    setSearchedText(value);

  const getLocationsDebounced = useDebouncedCallback(async (query: string) => {
    try {
      setLoading(true);
      const res = await searchListingById(query);

      if (res.data) {
        setSuggestions(res.data.listings);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, 800);

  useEffect(() => {
    if (searchedText.length) {
      getLocationsDebounced(searchedText);
    } else {
      setSuggestions([]);
      setSelectedListing(null);
      setLoading(false);
    }
    return () => {
      setSelectedListing(null);
      setSuggestions([]);
      setLoading(false);
    };
  }, [searchedText]);

  return selectedListing ? (
    <AddToMyListingModal
      listing={selectedListing}
      closeModal={() => setSelectedListing(null)}
      handleAddToMyListing={handleAddToMyListing}
    />
  ) : (
    <Modal className="LocationSearchModal" offClick={onClose}>
      <div className="ListingSearch__model-content">
        <div className="ListingSearch__search">
          <FontAwesomeIcon icon={faSearch} className="ListingSearch__icon" />
          <input
            placeholder="Search by listing ID"
            ref={inputRef}
            className="ListingSearch__input"
            type="text"
            value={searchedText}
            onChange={onChangeSearch}
            maxLength={50}
          />
        </div>
        {loading ? (
          <div
            style={{
              textAlign: 'center',
              margin: '2rem',
            }}
          >
            <div className="before-spinner" style={{ position: 'unset' }} />
          </div>
        ) : (
          suggestions.length > 0 && (
            <ul className="ListingSearch__suggestions">
              {suggestions.map(listing => (
                <li key={listing.listingid}>
                  <div className="ListingSearch__suggestion-button">
                    <div className="ListingSearch__suggestion-info">
                      <span className="ListingSearch__suggestion-info-name">
                        <b>{listing.street}</b> {listing.city} {listing.state}, {listing.zip}
                      </span>
                      <span className="ListingSearch__suggestion-info-id">
                        Listing ID: {listing.mls_id}
                      </span>
                    </div>
                    {listing.added && listing.is_owner ? (
                      <FontAwesomeIcon icon={faCheck} color="#FFAB03" />
                    ) : (
                      <button
                        className="ListingSearch__suggestion-add-into-listings"
                        onClick={onAddIntoMyListings(listing)}
                      >
                        {addListingLoading === listing.listingid ? (
                          <FontAwesomeIcon
                            icon={faSpinnerThird}
                            style={{
                              animation: 'spinner-border 0.75s linear infinite',
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon icon={faPlus} color="#FFAB03" />
                        )}
                        <span>Add to my listings</span>
                      </button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )
        )}
      </div>
    </Modal>
  );
};

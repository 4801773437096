import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { ProspectFinderSelection } from 'Pages/ProspectActivity/components';
import {
  VirtualJustListedJustSold,
  GrowYourSphere,
  SellYourListingFaster,
  LikelySellers,
  TalkToSphereLens,
  DigitalFarming,
} from 'Pages/ProspectActivity/lenses';
import { LensSidebar } from 'Pages/ProspectActivity/lenses/components';

import { setSidebarLens } from 'Redux/actions';

import type { TRootState } from 'types';

import './ProspectActivityPage.scss';

const premiumLenses = ['/grow-your-sphere', '/likely-sellers'];

export const ProspectActivityPage = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const { userData } = useSelector((state: TRootState) => state?.userReducer);

  useEffect(() => {
    dispatch(setSidebarLens(true));
    if (
      userData.sub_name === 'basic' &&
      !!premiumLenses.find(path => location.pathname.includes(path))
    )
      history.replace('/prospects');
  }, [location.pathname, userData]);

  return (
    <div className="ProspectActivityPage">
      <Switch>
        <Route exact path={path}>
          <LensSidebar>
            <ProspectFinderSelection />
          </LensSidebar>
        </Route>
        <Route path={`${path}/digital-farming`} component={DigitalFarming} />
        <Route
          path={`${path}/grow-your-sphere`}
          render={() => (userData.sub_name === 'premium' ? <GrowYourSphere /> : null)}
        />
        <Route
          path={`${path}/likely-sellers`}
          render={() => (userData.sub_name === 'premium' ? <LikelySellers /> : null)}
        />
        <Route path={`${path}/sell-your-listing-faster`} component={SellYourListingFaster} />
        <Route
          path={`${path}/virtual-just-listed-just-sold`}
          component={VirtualJustListedJustSold}
        />
        <Route path={`${path}/talk-to-your-sphere`} component={TalkToSphereLens} />
        <Route render={() => <Redirect to="/404" />} />
      </Switch>
    </div>
  );
};

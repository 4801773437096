import React, { useEffect } from 'react';

import { FlexBox, Text } from '@eltoro-ui/components';

const SocialMediaPosting = () => {
  useEffect(() => {
    addJavascript(
      'https://dashboard-dev.messagespring.com/assets/socialMediaIntegrationsBeewoSDK/sdk.js',
      '__messagespring_widget_script',
      () => {
        // @ts-ignore
        if (window.MS) {
          // @ts-ignore
          window.MS.init('#myContainer');
        }
      }
    );
  }, []);

  const addJavascript = (src: string, id: string, callback: VoidFunction) => {
    if (id && document.getElementById(id)) {
      // the script is already loaded so just invoke the callback and return
      if (callback) callback();
      return;
    }

    // create and add the scrpt tag
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    if (id) {
      scriptTag.id = id;
    }

    // set the script to invoke a callback after it loads
    if (callback) {
      if (scriptTag.readyState) {
        // IE7+
        scriptTag.onreadystatechange = () => {
          if (scriptTag.readyState === 'loaded' || scriptTag.readyState === 'complete') {
            // clear the callback so it only ever executes once
            scriptTag.onreadystatechange = null;
            callback();
          }
        };
      } else {
        scriptTag.onload = () => {
          // Other browsers support the onload attribute \o/
          callback();
        };
      }
    }

    // assign the src attribute
    scriptTag.src = src;
    // add the script to the page
    document.body.appendChild(scriptTag);
  };

  return (
    <FlexBox flexDirection="column">
      <Text on="white" weight="bold" UNSAFE_className="title">
        Social Media Posting
      </Text>
      <div id="myContainer" />
    </FlexBox>
  );
};

export default SocialMediaPosting;
